import React from "react";
import DatePicker from "react-datepicker/es";
import ProductOptions from './ProductOptions'


export class OrderForm extends React.Component {
    state = {
        orderID: this.props.order.id,
        orderTotal: 0,
        failOrder: this.props.failOrder,
        fail: false
    };

    changeTime = () => {
        const {timeNow, order} = this.props;
        const {start} = order.product.main.time[new Date(order.date).getDay()];
        if (new Date().getDate() === order.date.getDate()) {
            if (timeNow.getHours() > parseInt(start[0])) {
                console.log('AAA')
            } else {
                this.setState({
                    failOrder: false
                })
            }
        }
    };

    render() {
        const {
            products,
            order,
            selectedStart,
            changeActiveProduct,
            changeDate,
            getAvailableDates,
            addOption,
            removeOption,
            changeOption,
            changeTime,
            reserve,
            subscriptionChange,
            userSubscriptions,
            usedChildSubscription,
            visit,
            activeProductId,
            orderGood,
            changePoolTime,
            buyOrder,
            poolTime,
            poolPrice,
            index,
            orders,
            eveningPreferredProduct,
            userBday,
            userDiscount,
            changePoolDiscount,
            changeDiscount,
            loadingSubscription
        } = this.props;
        const bt = [...order.product.busyTimes];
        const startTime = [], endTime = [];
        const {start, end} = order.product.main.time[new Date(order.date).getDay()];
        let counter = 0,
            startDateTime = new Date(`${new Date(order.date).getFullYear()}-${new Date(order.date).getMonth() + 1}-${new Date(order.date).getDate()} ${start}`).getTime(),
            endDateTime = new Date(`${new Date(order.date).getFullYear()}-${new Date(order.date).getMonth() + 1}-${new Date(order.date).getDate()} ${end}`).getTime();
        startTime.push(startDateTime);

        while (startDateTime < endDateTime) {
            if (counter < 1) {
                startDateTime += 3600000;
            } else {
                startDateTime += 1800000;
            }
            if (startDateTime < endDateTime - 1800000) {
                startTime.push(startDateTime);
            }
            endTime.push(startDateTime);
            counter += 1;
        }
        if (!products.length) {
            return <div/>
        }
        return (
            <form className="orderItem">
                {order.id !== 0 && visit === true && orders.length > 1 ?
                <div className="removeOrder" onClick={() => {
                    this.props.removeOrder(order.id)
                }}>Удалить услугу
                </div> : null}
                <div className="productHeader">

                    <select className="productSelect" name="product" id={order.id} onChange={(e) => {
                        changeActiveProduct(order.id, e.target.value, order, order.product.main, products, index, //getDiscount(products, e.target.value)
                        )
                    }}>
                        {products.map(p => {
                            if (p.main.id === order.product.main.id) {
                                return (
                                    <option key={p.main.id.toString()} value={p.main.id}>{p.main.name}</option>
                                )
                            }
                            return (
                                <option key={p.main.id.toString()} value={p.main.id}>{p.main.name}</option>
                            )
                        })}
                    </select>
                    <div className="productCalendar">
                        <DatePicker
                            dropdownMode="scroll"
                            selected={order.date}
                            popperPlacement="bottom-end"
                            minDate={new Date()}
                            dateFormat='dd-MM-YYYY'
                            onMonthChange={(e) => getAvailableDates(e, order.product.main.id, order.id)}
                            onChange={(e) => {
                                changeDate(order.id, e, order.product.main)
                            }}
                            excludeDates={order.product.busyDates.map(d => new Date(`${new Date().getFullYear()}-${d}`))}
                            dayClassName={dat => {
                                let month = new Date(dat).getMonth() + 1;
                                let date = new Date(dat).getDate();
                                date = date < 10 ? '0' + date : date;
                                month = month < 10 ? '0' + month : month;
                                const str = `${month}-${date}`;
                                if (order.product.holidays.find(d => d === str)) {
                                    return 'holiday'
                                }
                                return undefined
                            }
                            }
                        />
                    </div>
                </div>

                <div className="timeInputs">
                    <select name="starttime" id="starttime" onChange={(e) => {
                        order.product.main.id === '05' ? changePoolTime(e.target.value, order.id, order.product.main) : changeTime(order.id, 'start', e.target.value, order.product.main);
                    }}>
                        {   //activeProductId === '05'
                            order.product.main.id === '05'
                                ?
                                <React.Fragment>
                                    <option value="10:00">10:00</option>
                                    <option value={order.product.main.day_time}>{order.product.main.day_time}</option>
                                    <option
                                        value={order.product.main.evening_time}>{order.product.main.evening_time}</option>
                                </React.Fragment>
                                :
                                order.product.main.id === '01'
                                    ?
                                    <option
                                        value={startTime[0]}>{`${new Date(startTime[0]).getHours()}:${new Date(startTime[0]).getMinutes() === 0 ? '0' + new Date(startTime[0]).getMinutes() : new Date(startTime[0]).getMinutes()}`}</option>
                                    :
                                    startTime.filter((t, i) => {
                                        return !bt.find(o => {
                                            return new Date(`${new Date(order.date).getFullYear()}-${new Date(order.date).getMonth() + 1}-${new Date(order.date).getDate()} ${o.start}`).getTime() <= t && (new Date(`${new Date(order.date).getFullYear()}-${new Date(order.date).getMonth() + 1}-${new Date(order.date).getDate()} ${o.end}`).getTime() - t) >= 3600000
                                        })
                                    }).map(t =>
                                        <option
                                            key={t.toString()}
                                            value={t}>{`${new Date(t).getHours()}:${new Date(t).getMinutes() === 0 ? '0' + new Date(t).getMinutes() : new Date(t).getMinutes()}`}</option>)
                        }
                    </select>

                    <select name="endtime" id="endtime" onChange={(e) => {
                        changeTime(order.id, 'end', e.target.value, order.product.main)
                    }}>
                        {order.product.main.id === '05'
                            ?
                            <option value="20:00">20:00</option>
                            :
                            order.product.main.id === '01'
                                ?
                                order.date.getDay() === 0 || order.date.getDay() === 6 || order.date.getDay() === 7
                                    ?
                                    <option value={endTime[20]}>
                                        {`${new Date(endTime[20]).getHours()}:${new Date(endTime[20]).getMinutes() === 0 ? '0' + new Date(endTime[20]).getMinutes() : new Date(endTime[20]).getMinutes()}`}
                                    </option>
                                    :
                                    <option value={endTime[14]}>
                                        {`${new Date(endTime[14]).getHours()}:${new Date(endTime[14]).getMinutes() === 0 ? '0' + new Date(endTime[14]).getMinutes() : new Date(endTime[14]).getMinutes()}`}
                                    </option>
                                :
                                endTime.filter(t => {
                                    const sStart = selectedStart === null ? startTime[0] : selectedStart;
                                    return (t - sStart) >= 3600000 && !bt.filter(o => {
                                        return new Date(`${new Date(order.date).getFullYear()}-${new Date(order.date).getMonth() + 1}-${new Date(order.date).getDate()} ${o.start}`) > sStart
                                    }).find(o => {
                                        return t > new Date(`${new Date(order.date).getFullYear()}-${new Date(order.date).getMonth() + 1}-${new Date(order.date).getDate()} ${o.start}`).getTime()
                                    })
                                }).map(t => <option
                                    key={t.toString()}
                                    value={t}>{`${new Date(t).getHours()}:${new Date(t).getMinutes() === 0 ? '0' + new Date(t).getMinutes() : new Date(t).getMinutes()}`}</option>)
                        }
                    </select>
                </div>
                {this.props.reserve === false
                    ?
                    <React.Fragment>
                        {visit === true
                            ?
                            order.product.main.id !== '05'
                                //activeId !== '05'
                                ?
                                <div className="peopleCount">
                                    <div className="pplTitle">
                                        <div>Количество людей</div>
                                        <div>{order.price} грн. с человека</div>
                                    </div>
                                    <div className="pplControl">
                                        <button onClick={(e) => {
                                            e.preventDefault();
                                            this.props.minusPeople(order.id)
                                        }} className="minus">-
                                        </button>
                                        <div>{order.peopleCount}</div>
                                        <button onClick={(e) => {
                                            e.preventDefault();
                                            this.props.plusPeople(order.id)
                                        }} className="plus">+
                                        </button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="peopleCount">
                                        <div className="pplTitle">
                                            <div>Количество взрослых</div>
                                            {poolTime === order.product.main.evening_time || poolTime === order.product.main.day_time
                                                ?
                                                <div>{poolPrice} грн. с человека</div>
                                                :
                                                <div>{order.price} грн. с человека</div>
                                            }
                                        </div>
                                        <div className="pplControl">
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                this.props.minusPeople(order.id, 'peopleMinus')
                                            }} className="minus">-
                                            </button>
                                            <div>{order.peopleCount}</div>
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                this.props.plusPeople(order.id, 'peoplePlus', order)
                                            }} className="plus">+
                                            </button>
                                        </div>
                                    </div>
                                    <div className="peopleCount">
                                        <div className="pplTitle">
                                            <div>Количество детей ( до 140 см )</div>
                                            {
                                                poolTime === order.product.main.evening_time || poolTime === order.product.main.day_time
                                                    ?
                                                    <div>{poolPrice} грн. с человека</div>
                                                    :
                                                    <div>{order.price} грн. с человека</div>
                                            }
                                        </div>
                                        <div className="pplControl">
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                this.props.childrenMinus(order.id, 'childrenMinus')
                                            }} className="minus">-
                                            </button>
                                            <div>{order.childrenCount}</div>
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                this.props.childrenPlus(order.id, 'childrenPlus', order)
                                            }} className="plus">+
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            :
                            null
                        }
                        <div>
                            {(
                                () => {
                                    if (order.product.products) {
                                        return (
                                            order.product.products.map(opt => {
                                                //const opts = order.options.filter(o => o.id === opt.id);
                                                //const id = order.options.filter(o => o.id);
                                                //const orderId = id.map( o =>  o.id);
                                                return <ProductOptions
                                                    prefered={opt.preferred}
                                                    option={opt}
                                                    order={order}
                                                    orderId={order.id}
                                                    key={(opt.id.toString())}
                                                    //orderOptions={order.product.products.filter(o => o.id === orderId[0] || orderId[1])}
                                                    orderOptions={order.options.filter(o => o.id === opt.id)}
                                                    addOption={addOption}
                                                    removeOption={removeOption}
                                                    changeOption={changeOption}
                                                    reserve={reserve}
                                                    orderPrefferedProduct={order.productCount}
                                                    eveningPreferredProduct={eveningPreferredProduct}
                                                />
                                            })
                                        )
                                    }
                                }
                            )()}
                        </div>
                        {userDiscount === true
                            ?
                            order.product.main.id === '05'
                                ?
                                <div className="peopleCount discount" style={{marginTop: '20px'}}>
                                    <div className="pplTitle">
                                        <div className="title-discount" style={{fontSize: '17px'}}>Поздравляем Вас с
                                            Днем Рождения! В этот день для
                                            Вас вход ВСЕГО 1 грн.
                                        </div>
                                    </div>
                                    <div className="changed-discount">
                                        <label htmlFor="pool-discount" style={{marginRight: '5px'}}>Использовать</label>
                                        <input className="checkbox"
                                               onChange={(e) => this.props.changedPoolDiscount(order.id, e, order)}
                                               checked={this.props.changePoolDiscount} id="pool-discount"
                                               type="checkbox"/>
                                    </div>
                                </div>
                                :
                                order.product.main.id === '01'
                                    ?
                                    <div className="peopleCount discount" style={{marginTop: '20px'}}>
                                        <div className="pplTitle">
                                            <div className="title-discount" style={{fontSize: '17px'}}>Поздравляем Вас с
                                                Днем Рождения! В этот день
                                                для Вас вход ВСЕГО 1 грн.
                                            </div>
                                        </div>
                                        <div className="changed-discount">
                                            <label htmlFor="discount" style={{marginRight: '5px'}}>Использовать</label>
                                            <input className="checkbox"
                                                   onChange={(e) => this.props.changedDiscount(order.id, e, order)}
                                                   checked={this.props.changeDiscount} id="discount"
                                                   type="checkbox"/>
                                        </div>
                                    </div>
                                    :
                                    null
                            :
                            null
                        }
                        {/*{order.product.subscriptions !== false &&*/}
                        {/*<div onClick={this.props.tosubscribe} className="buySubscription"><span>Купить абонемент</span>*/}
                        {/*</div>*/}
                        {/*}*/}
                        {order.product.subscriptions !== false && (activeProductId === '05' || loadingSubscription === true)
                            ?
                            userSubscriptions !== null && !buyOrder ?
                                <div onClick={() => this.props.changeUserSubscription()} className="sendOrder"><span>Использовать абонемент</span>
                                </div>
                                :
                                null
                            :
                            null
                        }
                        {/*{this.state.fail === true*/}
                        {/*    ?*/}
                        {/*    <span className="error-order">Выберите корректную дату и время</span> : null}*/}
                        {subscriptionChange === true
                            ?
                            <span style={{color: 'black', background: '#ffcd54', border: 'none'}}
                                  className="error-order">Абонемент выбран</span> : null}
                        {usedChildSubscription === true
                            ?
                            <span style={{color: 'black', background: '#ffcd54', border: 'none'}}
                                  className="error-order">Посещение аквапарка без взрослого недоспустимо</span>
                            :
                            null
                        }
                        {orderGood === true
                            ?
                            <span style={{color: 'black', background: '#ffcd54', border: 'none'}}
                                  className="error-order">Заказ выполнен</span>
                            :
                            null
                        }
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {order.product.subscriptions !== false &&
                        <div onClick={this.props.tosubscribe} className="buySubscription"><span>Купить абонемент</span>
                        </div>
                        }
                        {order.product.subscriptions !== false && loadingSubscription === true
                            ?
                            userSubscriptions !== null ?
                                <div onClick={() => this.props.changeUserSubscription()} className="sendOrder"><span>Использовать абонемент</span>
                                </div> : null
                            :
                            null
                        }
                    </React.Fragment>
                }
            </form>
        )
    }
}

export default OrderForm;