import React, {Component} from "react";
import FormControl from "../components/FormControl/FormControl";
import {BASE_URL} from '../SignInOutModal';

export class SignUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isValid: true,
            isExist: false,
            isError: false,

            isPhoneValid: true,

            needConfirm: null,

            newUser: {
                fname: null,
                sname: null,
                phone: null,
                email: null,
                pass: null,
                bday: null
            },

            userExist: false,

            continueRegistration: false
        };
    }

    handleChangeFname = (data) => {
        this.setState({
            newUser: {
                fname: data,
                sname: this.state.newUser.sname,
                phone: this.state.newUser.phone,
                email: this.state.newUser.email,
                bday: this.state.newUser.bday,
                pass: this.state.newUser.pass
            }
        })
    };
    handleChangeSname = (data) => {
        this.setState({
            newUser: {
                fname: this.state.newUser.fname,
                sname: data,
                bday: this.state.newUser.bday,
                phone: this.state.newUser.phone,
                email: this.state.newUser.email,
                pass: this.state.newUser.pass
            }
        })
    };
    handleChangePhone = (data) => {
        if (data.length > 13 || data.search(/_/) > -1) {
            this.setState({isPhoneValid: false})
        } else {
            this.setState({isPhoneValid: true})
        }

        this.setState({
            newUser: {
                fname: this.state.newUser.fname,
                sname: this.state.newUser.sname,
                phone: data,
                bday: this.state.newUser.bday,
                email: this.state.newUser.email,
                pass: this.state.newUser.pass
            },
            isValid: true
        })
    };

    handleChangeEmail = (data) => {
        this.setState({
            newUser: {
                fname: this.state.newUser.fname,
                sname: this.state.newUser.sname,
                phone: this.state.newUser.phone,
                email: data,
                bday: this.state.newUser.bday,
                pass: this.state.newUser.pass
            }
        })
    };

    handleChangeDate = (data) => {
        this.setState({
            newUser: {
                fname: this.state.newUser.fname,
                sname: this.state.newUser.sname,
                phone: this.state.newUser.phone,
                email: this.state.newUser.email,
                bday: data,
                pass: this.state.newUser.pass
            }
        })
    };

    handleChangePass = (data) => {
        this.setState({
            newUser: {
                fname: this.state.newUser.fname,
                sname: this.state.newUser.sname,
                phone: this.state.newUser.phone,
                email: this.state.newUser.email,
                bday: this.state.newUser.bday,
                pass: data,
            }
        })
    };

    handleChangeCode = (data) => {
        this.setState({code: data})
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({isLoading: true});
        if (this.state.newUser.phone !== null || this.state.newUser.pass !== null) {

            let data = {
                'name': this.state.newUser.fname,
                'sname': this.state.newUser.sname,
                'phone': this.state.newUser.phone,
                'password': this.state.newUser.pass,
                'email': this.state.newUser.email,
                'bday': this.state.newUser.bday,
                'code': this.state.code,
                'sign': this.state.sign
            };
            let form = new URLSearchParams();
            form.append('name', data.name);
            form.append('sname', data.sname);
            // form.append('phone', data.phone);
            form.append('password', data.password);
            if (data.email !== null && data.email !== '' && data.email !== undefined) {
                form.append('email', data.email);
            }
            form.append('bday', data.bday);

            fetch(`${BASE_URL}register?phone=${encodeURIComponent(data.phone)}&code=${encodeURIComponent(data.code)}&sign=${data.sign}`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: form,
                method: 'post',
            }).then(res => {
                this.setState({isLoading: false});
                console.log(res);
                switch (res.status) {
                    case 200:
                        this.setState({isLoading: false});
                        return res.json();
                    case 302:
                        this.setState({
                            isExist: false
                        });
                        this.setState({isLoading: false});
                        break;
                    case 303:
                        if (this.state.isValid) {
                            this.setState({
                                isValid: !this.state.isValid
                            });
                        }
                        this.setState({isLoading: false});
                        break;
                    default: // 500
                        this.setState({
                            isError: true
                        });
                        this.setState({isLoading: false});
                        break;
                }

            }).then(res => this.props.onRegister(res.user, res.sign, res.code))
                .catch(error => console.log('Fetch error ' + error.message));

        } else {
            alert('Ошибка!')
        }
    };

    handleSubmitPhone = e => {
        e.preventDefault();
        this.setState({isLoading: true})
        fetch(`${BASE_URL}register?phone=${encodeURIComponent(this.state.newUser.phone)}`, {
            method: "GET"
        }).then(res => {
            switch (res.status) {
                case 200:
                    return res.json()
                case 303:
                    this.setState({userExist: true, isLoading: false})
                    break;
                default:
                    this.setState({isLoading: false})
                    break;
            }
        }).then(res => {
            this.setState({
                newUser: {
                    fname: this.state.newUser.fname,
                    sname: this.state.newUser.sname,
                    phone: this.state.newUser.phone,
                    email: this.state.newUser.email,
                    pass: this.state.newUser.pass
                },
                sign: res.sign,
                continueRegistration: true,
                isLoading: false
            })
        }).catch(err => console.log(err));
    }

    renderPhoneForm() {
        const {isError, isPhoneValid, isLoading, userExist} = this.state;

        return (
            <form onSubmit={this.handleSubmitPhone} className="auth-form">
                <div className="auth-blockTitle">Регистрация</div>
                <FormControl handleValidation={isPhoneValid}
                             handleChange={this.handleChangePhone} type="tel" name="Телефон" id={2}/>
                {userExist && <div className="auth-error">Пользователь уже зарегестрирован!</div>}
                {isError && <div className="auth-error">Ошибка на сервере, попробуйте позже!</div>}
                {!isPhoneValid && <div className="auth-error">Проверте правильность ввода телефона!</div>}

                <button type="submit" className="appSubmit" disabled={!this.state.isPhoneValid}>
                    {
                        isLoading ?
                            <div className="auth-loading"/>
                            :
                            <span>Отправить</span>
                    }
                </button>
            </form>
        )
    }

    renderFullForm() {
        const {isError, isExist, isLoading, isValid} = this.state;
        return (
            <form onSubmit={this.handleSubmit} className="auth-form">
                <div className="auth-blockTitle">Регистрация</div>
                <FormControl handleValidation={true}
                             handleChange={this.handleChangeFname} type={'text'} name="Имя"/>
                <FormControl handleValidation={true}
                             handleChange={this.handleChangeSname} type={'text'} name="Фамилия"/>
                <FormControl
                    handleValidation={true}
                    handleChange={this.handleChangeEmail}
                    required={false}
                    type={'email'}
                    name="Email"
                />
                <FormControl handleValidation={true}
                             handleChange={this.handleChangeDate} type={'date'} name="Дата рождения"/>
                <FormControl handleValidation={true}
                             handleChange={this.handleChangePass} type={'password'} name="Пароль" id={2}/>
                <FormControl handleValidation={isValid}
                             handleChange={this.handleChangeCode} type={'text'} name="SMS Код"/>
                {!isValid && <div className="auth-error">Неверный код!</div>}
                {isExist && <div className="auth-error">Такой пользователь уже существует!</div>}
                {isError && <div className="auth-error">Ошибка на сервере, попробуйте позже!</div>}

                <button type="submit" className="appSubmit">
                    {
                        isLoading ?
                            <div className="auth-loading"/>
                            :
                            <span>Зарегистрироваться</span>
                    }
                </button>
            </form>
        );
    }

    render() {
        return (
            <>
                {this.state.continueRegistration === false
                    ? this.renderPhoneForm()
                    : this.renderFullForm()}
            </>
        );
    }
}

export default SignUp;