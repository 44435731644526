import React, { Component } from 'react';
// import axios from "axios";

export class Subscriptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptions: props.array,
            selected: props.array[0],

            count_adult: 1,
            count_child: 0,

            order: [
                {
                    id: null,
                    amount: 0
                },
                {
                    id: null,
                    amount: 0
                }
            ],
            total: null
        }
    }

    componentDidMount() {
        this.calcTotal(this.state.count_adult, this.state.count_child);
        this.setState({ selected: this.state.subscriptions[0] });
    }

    handleChange = (e) => {
        this.setState({
            selected: { ...this.state.subscriptions.find(s => s._id === e.target.value) }
        });
    };

    addAdult = () => {
        const am = this.state.count_adult + 1;
        this.setState({
            count_adult: am
        })
        this.calcTotal(am, this.state.count_child);
    }

    remAdult = () => {
        if (this.state.count_adult > 1) {
            const am = this.state.count_adult - 1;
            this.setState({
                count_adult: am
            });
            this.calcTotal(am, this.state.count_child);
        } else {
            this.calcTotal(this.state.count_adult, this.state.count_child);
        }
    }
    addChild = () => {
        const am = this.state.count_child + 1;
        this.setState({
            count_child: am
        })
        this.calcTotal(this.state.count_adult, am);
    }

    remChild = () => {
        if (this.state.count_child > 0) {
            const am = this.state.count_child - 1;
            this.setState({
                count_child: am
            });
            this.calcTotal(this.state.count_adult, am)
        } else {
            this.calcTotal(this.state.count_adult, this.state.count_child)
        }
    }

    calcTotal = (am_adult, am_child) => {
        const order = [
            {
                id: this.state.subscriptions[0].id,
                amount: am_adult
            },
            {
                id: this.state.subscriptions[1].id,
                amount: am_child
            }
        ];

        const total = this.state.subscriptions[0].cost * order[0].amount + this.state.subscriptions[1].cost * order[1].amount

        this.setState({ order, total })
    }

    render() {
        const { subscriptions, total } = this.state;

        // const count_str_vst = selected.visits.toString().slice(-1) === '2' || selected.visits.toString().slice(-1) === '3' || selected.visits.toString().slice(-1) === '4' ? 'раза' : 'раз'
        // const count_str_ppl = selected.visits.toString().slice(-1) === '2' || selected.visits.toString().slice(-1) === '3' || selected.visits.toString().slice(-1) === '4' ? 'человека' : 'человек'
        return (
            <div className="subscriptions">
                <div className="subscriptionItem">
                    <div className="sTitle">{subscriptions[0].name}</div>
                    <p>{subscriptions[0].description}</p>
                    <p>Доступно: <strong>{subscriptions[0].limit} шт.</strong></p>
                    <div className="sPrice">Цена абонемента составляет <strong>{subscriptions[0].cost} грн</strong></div>
                </div>
                <div className="butBlock">
                    <div onClick={this.remAdult} className="btn-quantity">-</div>
                    <div className="counter">{this.state.count_adult}</div>
                    <div onClick={this.addAdult} className="btn-quantity">+</div>
                </div>
                <div className="subscriptionItem">
                    <div className="sTitle">{subscriptions[1].name}</div>
                    <p>{subscriptions[1].description}</p>
                    <p>Осталось к покупке: <strong>{subscriptions[1].limit} шт.</strong></p>
                    <div className="sPrice">Цена абонемента составляет <strong>{subscriptions[1].cost} грн</strong></div>
                </div>
                <div className="butBlock">
                    <div onClick={this.remChild} className="btn-quantity">-</div>
                    <div className="counter">{this.state.count_child}</div>
                    <div onClick={this.addChild} className="btn-quantity">+</div>
                </div>
                <div className="subscrFooter">
                    <div className="total">Стоимость заказа составляет <strong>{total} грн</strong></div>
                    <div style={{marginRight: '10px'}} className="sendOrder" onClick={() => this.props.handleBuy(this.state.order)}><span>Купить</span></div>
                    <div style={{marginRight: '10px'}} className="sendOrder" onClick={() => this.props.cancelBuy()}><span>Отменить</span></div>
                </div>
                 {/*<div className="backMain" onClick={this.props.backMain}><span>Назад</span></div> */}
            </div>
        )
    }
}

export default Subscriptions;