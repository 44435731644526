import React, { Component } from "react";

import InputMask from 'react-input-mask';

export class FormControl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        this.props.handleChange({id: e.target.id , value: e.target.value});
    };

    render() {
        const { type, id, isValid,  value, readOnly, required, label } = this.props;
        return (
            <div className={!isValid ? 'namedInput invalid' : 'namedInput' }>
                {
                    type === 'tel' ?
                        <InputMask placeholder="." required={required} mask="+380999999999" onChange={this.handleChange} value={value || ''} id={id} /> :
                        <input placeholder="."
                               autoComplete="off"
                               className="appInput"
                               onChange={this.handleChange}
                               value={value || ''}
                               id={id}
                               type={type}
                               readOnly={readOnly}
                        />
                }
                <label htmlFor={id}>{label}</label>
            </div>
        )
    }
}

export default FormControl;