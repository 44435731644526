import React from "react";

export class ProductOptions extends React.Component {
    render() {
        const {
            prefered,
            option,
            order,
            orderOptions,
            orderId,
            addOption,
            removeOption,
            changeOption,
            orderPrefferedProduct,
            eveningPreferredProduct
        } = this.props;
        if (option.amount) {
            return (
                <div className="productOption">
                    <div className="optTitle">
                        <div>{option.name}</div>
                    </div>
                    <div className="selecter">
                        <div className="optControl">
                            <button onClick={(e) => {
                                removeOption(e, orderId, option.id, order.product.main.id, option.amount[0].option)
                            }}>-
                            </button>
                        </div>
                        {orderOptions.map((o, index) => {
                            return <div key={index.toString()}>
                                <div className="optionName">{option.optionName}</div>
                                <div className="multioptions">
                                    {o.amount.map((oa, i) => {
                                        return <select key={i.toString()} onChange={(e) => {
                                            changeOption(orderId, option.id, order.product.main.id, e.target.value.split('-')[0], i, e.target.value.split('-')[2])
                                        }}>
                                            {option.amount.map((a, i) => {
                                                return <option key={a.option}
                                                               value={a.option + '-' + i}>{a.option}</option>
                                            })}
                                        </select>
                                    })}
                                </div>
                            </div>
                        })}
                        <div className="optControl">
                            <button onClick={(e) => {
                                addOption(e, orderId, option.id, order.product.main.id, option.amount[0].option)
                            }}>+
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="productOption">
                <div className="optTitle">
                    <div>{option.name}</div>
                    {option.id === '16' ? <div>{option.cost} грн. с ребенка</div> : <div>{option.cost} грн/шт</div>}
                </div>
                <div className="optControl">
                    <button onClick={(e) => {
                        removeOption(e, orderId, option.id, order.product.main.id)
                    }}>-
                    </button>
                    <div>
                        {orderOptions.find(o => o.id === option.id)
                            ?
                            orderOptions.find(o => o.id === option.id).amount
                            // prefered
                            //     ?
                            //     // eveningPreferredProduct === true  // when change evening time "18:00"
                            //     //     ?
                            //         orderPrefferedProduct //- 1
                            //         // :
                            //         // orderPrefferedProduct
                            //     :
                            //     orderOptions.find(o => o.id === option.id).amount
                            // // :
                            // // prefered
                            // //     ?
                            // //     orderPrefferedProduct
                            :
                            0
                        }
                    </div>
                    {/*<div>*/}
                    {/*    {*/}
                    {/*        orderOptions.find(o => o.id === option.id) &&*/}
                    {/*        orderOptions.find(o => o.id === option.id).amount || 0*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <button onClick={(e) => {
                        addOption(e, orderId, option.id, order.product.main.id)
                    }}>+
                    </button>
                </div>
            </div>
        )
    }
}

export default ProductOptions