import React, {Component} from "react";
import FormControl from "../components/FormControl/FormControl";
import {BASE_URL} from "../SignInOutModal"
import Cookies from 'js-cookie';

export class SignIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: null,
            isLoading: false,
            isValid: true,

            newUser: {
                phone: null,
                pass: null
            }
        };
        this.handleSignIn = this.handleSignIn.bind(this);
    }

    handleChangePhone = (data) => {
        this.setState({
            newUser: {phone: data, pass: this.state.newUser.pass},
            isValid: true
        })
    };

    handleChangePass = (data) => {
        this.setState({
            newUser: {phone: this.state.newUser.phone, pass: data},
            isValid: true
        })
    };

    handleSignIn = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        if (this.state.newUser.phone !== null || this.state.newUser.pass !== null) {
            let data = {
                'phone': this.state.newUser.phone,
                'password': this.state.newUser.pass
            };
            let form = new URLSearchParams();
            form.append('phone', data.phone);
            form.append('password', data.password);

            fetch(`${BASE_URL}login`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: form,
                method: 'post',
            }).then(res => {
                this.setState({isLoading: false});
                switch (res.status) {
                    case 200:
                        this.setState({isLoading: false});
                        return res.json();
                    case 404:
                        this.setState({
                            isValid: false
                        });
                        this.setState({isLoading: false});
                        return false;
                    default:
                        alert('no user');
                        break;
                }
            }).then(res => {
                if (res) {
                    Cookies.set('zplAntrATkn-99', res.token, {secure: true, expires: 1, domain: '.skyantar.com'});
                    this.props.onLogin(res.token);
                }
            }).catch(error => console.log('Fetch error ' + error.message));
        } else {
            alert('Ошибка!')
        }
    };

    render() {
        const {isLoading} = this.state;
        return (
            <form className="auth-form" onSubmit={this.handleSignIn}>
                <div className="auth-blockTitle">Вход</div>
                <FormControl handleValidation={this.state.isValid}
                             handleChange={this.handleChangePhone} name="Телефон" type="tel"/>
                <FormControl handleValidation={this.state.isValid}
                             handleChange={this.handleChangePass} name="Пароль" type="password"/>
                {!this.state.isValid && <div className="auth-error">Неверный логин или пароль!</div>}
                <div className="auth-forgot" onClick={this.props.goRestore}>Забыли пароль?</div>
                <button type="auth-submit" className="appSubmit">
                    {
                        isLoading ?
                            <div className="auth-loading"/>
                            :
                            <span>Войти</span>
                    }
                </button>
            </form>
        );
    }
}

export default SignIn;