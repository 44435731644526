import React, {Component} from 'react';

class UserSubscriptions extends Component {

    render() {
        const {userName} = this.props;
        const subscriptions = this.props.userSubscriptions.subscriptions.map(s => {
            const date = new Date(s.expired.toString()).toLocaleDateString();
            return (
                <div key={s.id.toString()}>
                    <div className="subscriptionItem">
                        <div className="sTitle">{s.name}</div>
                        <p>{s.description}</p>
                        <p>Осталось количество посещений: <strong>{s.visitsAmount} шт.</strong></p>
                        <p>Одновременно могут войти по абонементу: <strong>{s.maxVisitsPerTime} шт.</strong></p>
                        <p>Действительный до: <strong>{date}</strong></p>
                        {s.relative.name !== false
                            ?
                            <div className="sPrice">Право на
                                использования <strong>{s.relative.sname} {s.relative.name}</strong></div>
                            :
                            s.maxVisitsPerTime < 50 ?
                                <div className="sPrice">
                                    Право на использования <strong>{userName.name} {userName.sname}</strong>
                                </div>
                                : null
                        }
                    </div>
                    {/*{s.relative.name === false &&}*/}
                    <div className="userSubscriptions">
                        <div className="btn-block">
                            <div onClick={() => this.props.addSubscriptionToOptions(s.id, s)}
                                 className="change-subscription"><span>Использовать</span></div>
                            <div onClick={() => this.props.cancelChangeSubscription()} className="change-subscription">
                                <span>Отменить</span></div>
                        </div>
                    </div>
                </div>
            )
        });
        return (
            <div>
                {subscriptions}
            </div>
        )
    }
}

export default UserSubscriptions;