import React, { Component } from 'react';
import FormControl from "../components/FormControl/FormControl";

export class EnterRestoreCode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isValid: true,

            isTimeout: false,

            phone: this.props.phone,
            sign: this.props.sign,
            code: null,

            timer: 60,
            timerChecked: true
        };
    }

    handleChange = (data) => {
        this.setState({
            code: data,
            isValid: true
        })
    };

    newCode = () => {

        this.setState({
            code: null,
            timerChecked: true,
            timer: 60
        });

        this.timer = setInterval(() => {
            if (this.state.timer === 1) {
                this.setState({
                    timerChecked: false,
                    // isValid: true,
                    // code: null
                });
                clearInterval(this.timer);
            }
            this.setState({
                timer: this.state.timer - 1
            })
        }, 1000);
        fetch(`https://apisky.zapleo.com/restore?phone=${encodeURIComponent(this.state.phone)}`, {
            method: 'get',
        }).then(res => {
            console.log(res);
            switch (res.status){
                default:
                    return res;
            }
        }).catch(error => console.log('Fetch error ' + error.message));
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ isLoading: true });

        fetch(`https://apisky.zapleo.com/restore/confirm?phone=${encodeURIComponent(this.state.phone)}&sign=${this.state.sign}&code=${this.state.code}`, {
            method: "GET"
        }).then(res => {
            this.setState({ isLoading: false });
            // console.log(res);
            switch (res.status) {
                case 200:
                    this.setState({ isLoading: false });
                    this.props.onEnter(this.state.phone,this.state.sign,this.state.code);
                    break;
                case 303:
                    this.setState({
                        isLoading: false,
                        isValid: false
                    });
                    return res.json();
                default: // 404
                    this.setState({
                        isValid: !this.state.isValid, isLoading: false
                    });
                    break;
            }

        }).then(res => console.log(res)).catch(error => {
            console.log('Fetch error: ' + error.message);
            this.setState({
                isValid: false,
                isLoading: false
            });
        });
    };

    componentDidMount() {
        this.timer = setInterval(() => {
            if (this.state.timer === 1) {
                this.setState({
                    timerChecked: false,
                    // isValid: true,
                    // code: null
                });
                clearInterval(this.timer);
            }
            this.setState({
                timer: this.state.timer - 1
            })
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        const { isValid, isLoading, timer, timerChecked } = this.state;


        return (
            <form className="auth-code" onSubmit={this.handleSubmit}>
                <div className="auth-blockTitle">Введите код из смс</div>
                <FormControl handleValidation={this.state.isValid}
                    handleChange={this.handleChange} type={"text"} name="SMS Код" />
                {!isValid && <div className="auth-error">Неверный код</div>}
                {timerChecked ?
                    <div className="auth-wait">Выслать новый код через {timer} сек</div> :
                    <div className="auth-new-code" onClick={this.newCode}>Выслать новый код</div>
                }
                <button disabled={isLoading && "disabled"} type="submit" className="appSubmit">
                    {
                        isLoading ?
                            <div className="auth-loading" />
                            :
                            <span>Отправить</span>
                    }
                </button>
            </form>
        )
    }

}

export default EnterRestoreCode;