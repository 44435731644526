import React from 'react';
import ReactDOM from 'react-dom';
import OrderWindow from './OrderForm/OrderWindow.js';
import SignInOutModal from './SingInOutModal/SignInOutModal';

import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(<SignInOutModal />, document.getElementById('buttonAuthReact')); // sing in form

if(document.getElementById('orderForm')){
      ReactDOM.render(<OrderWindow />, document.getElementById('orderForm')); // order form window
}

// ReactDOM.render(<App />, document.getElementById('buttonAuthReact'));

serviceWorker.unregister();
