import React, { Component } from "react";

import InputMask from 'react-input-mask';

export class FormControl extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        this.props.handleChange(e.target.value);
    };

    render() {
        const { type, name, id, handleValidation, required } = this.props;
        return (
            <div className={handleValidation ? 'namedInput' : 'namedInput invalid'}>
                {
                    type === 'tel' ?
                        <InputMask required mask="+380999999999" onChange={this.handleChange} /> :
                        <input placeholder="."
                            autoComplete="off"
                            className="appInput"
                            required={required !== undefined ? false : true}
                               onChange={this.handleChange}
                            id={`input-${name} ${id}`} type={type}
                        />
                }
                <label htmlFor={`input-${name} ${id}`}>{name}</label>

            </div>
        )
    }
}

export default FormControl;