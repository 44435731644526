import React, { Component } from 'react';
import FormControl from "../components/FormControl/FormControl";

export class EnterPhone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isValid: true,
            isExist: false,
            phone: null,
        };
    }

    handleChange = (phone) => {
        if (phone.length > 13 || phone.search(/_/) > -1) {
            this.setState({ isValid: false })
        } else {
            this.setState({
                isValid: true,
                phone
            })
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ isLoading: true });

        fetch(`https://apisky.zapleo.com/restore?phone=${encodeURIComponent(this.state.phone)}`, {
            method: 'get',
        }).then(res => {
            console.log(res);
            switch (res.status) {
                case 200:
                    this.setState({ isLoading: false });
                    return res.json();
                case 303:
                    this.setState({
                        isLoading: false,
                        isExist: true,
                        isValid: false
                    });
                    break
                default: // 404
                    this.setState({
                        isValid: !this.state.isValid, isLoading: false
                    });
                    return res.json()
            }
        }).then(res => this.props.onEnter(res.sign, res.phone)).catch(error => {
            console.log('Fetch error: ' + error.message);
            this.setState({
                isValid: !this.state.isError,
                isLoading: false
            });
        });
    };

    render() {
        const { isValid, isLoading, isExist } = this.state;

        return (
            <form className="auth-form" onSubmit={this.handleSubmit}>
                <div className="auth-blockTitle">Восстановление</div>
                <FormControl handleValidation={this.state.isValid}
                    handleChange={this.handleChange} type="tel" name="Телефон" />
                {!isValid && !isExist && <div className="auth-error no-user">Проверьте правильность ввода телефона</div>}
                {isExist && <div className="auth-error no-user">Такого пользователя нет!</div>}

                <button disabled={!isValid} type="auth-submit" className="appSubmit">
                    {
                        isLoading ?
                            <div className="auth-loading" />
                            :
                            <span>Отправить код</span>
                    }
                </button>
            </form>
        );
    }
}

export default EnterPhone;