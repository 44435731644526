import React, { Component } from 'react';
import FormControl from "../components/FormControl/FormControl";

export class RestorePass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEqual: true,
            isLoading: false,
            isEqualToOld: false,

            newPass: '',
            repPass: ''
        };
    }

    handleChange = (newPass) => {
        if (newPass === this.state.repPass) {
            this.setState({
                newPass,
                isEqual: true
            })
        } else {
            this.setState({
                newPass,
                isEqual: false
            })
        }
    };

    handleChangeRepeat = (repPass) => {
        if (repPass === this.state.newPass) {
            this.setState({
                repPass,
                isEqual: true
            })
        } else {
            this.setState({
                repPass,
                isEqual: false
            })
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.isEqual && !this.state.isEqualToOld) {
            console.log('go');
            let form = new URLSearchParams();
            form.append('password', this.state.repPass);
            fetch(`https://apisky.zapleo.com/restore/confirm?phone=${encodeURIComponent(this.props.phone)}&sign=${this.props.sign}&code=${this.props.code}`, {
                method: "POST",
                body: form
            }).then(res => {
                switch (res.status) {
                    case 200:
                        this.props.onRestore();
                        break;
                    case 303:
                        break;
                    default:
                        break;
                }
            })
        }
    };

    render() {
        const { isEqual, isLoading, isEqualToOld } = this.state;

        return (
            <form className="auth-form" onSubmit={this.handleSubmit}>
                <div className="auth-blockTitle">Восстановление</div>
                <FormControl handleValidation={!isEqualToOld || this.state.isEqual}
                    handleChange={this.handleChange} type="password" value={this.state.newPass} name="Новый пароль" />
                <FormControl handleValidation={this.state.isEqual}
                    handleChange={this.handleChangeRepeat} type="password" value={this.state.repPass} name="Повторите пароль" />
                {!isEqual && <div className="auth-error">Пароли не совпадают!</div>}
                <button disabled={isLoading && "disabled"} type="auth-submit" className="appSubmit">
                    {
                        isLoading ?
                            <div className="auth-loading" />
                            :
                            <span>Изменить пароль</span>
                    }
                </button>
            </form>
        );
    }
}

export default RestorePass;