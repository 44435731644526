import React, {
    Component
} from 'react';
import axios from 'axios';
import './OrderWindow.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import OrderForm from './components/OrderForm'
import Subscriptions from './components/Subscriptions';
import UserSubscriptions from './components/UserSubscriptions';
import Cookies from 'js-cookie';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';

import {BASE_URL} from '../SingInOutModal/SignInOutModal';
import FormControl from './components/FormControl';

class OrderWindow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuth: false,
            isEnterCred: true,   //false
            buyOrder: false,
            authToken: Cookies.get('zplAntrATkn-99'),

            token: null,
            user: {
                phone: '',
                email: ''
            },
            userName: {
                name: '',
                sname: ''
            },

            userBday: '',
            bdayVerified: '',
            userDiscount: false,
            changePoolDiscount: false,
            changeDiscount: false,
            userRelatives: [],
            isLoading: true,
            isSubscribe: false,    //return to TRUE (default)

            products: [],
            productsIds: ['01', '02', '03', '04', '05'],
            orders: [],
            subscriptions: [],
            userSubscriptions: [],
            service: [],

            total: 0,
            isNoBtn: false,

            payForm: null,
            isPayForm: false,
            selectedFirst: this.props.selectedProduct,
            checkoutObject: null,
            subscrTotal: null,

            reserve: false,
            visit: true,

            cancelBuySubscription: false,
            changeSubscription: false,
            subscriptionChange: false,
            changeSubsc: false,
            subscriptionId: null,
            subscriptionChild: null,
            usedChildSubscription: false,

            userOrders: [],

            failOrder: false,
            changeActiveDate: false,

            timeNow: null,
            activeProductId: null,
            secondActiveId: null,
            orderGood: false,

            changeSecondOrder: false,
            dateForSubscription: null,

            dayPoolTime: null,
            eveningPoolTime: null,
            poolTimeWork: null,
            price: '',
            poolPrice: null,
            poolProduct: [],
            poolOrder: [],
            dayWeek: null,
            preordered: null,

            errorChangePhone: false,
            btnOrder: false,
            changePhone: false,

            totalPriceOrder: null,

            dinnerPoolTime: null,
            productsIdForDiscount: [],
            removeDiscountTrampoline: false,
            eveningPreferredProduct: false,
            changeOption: false,
            checkboxRules: false,
            showRules: false,
        };
        this.enterCreds = this.enterCreds.bind(this)

    };

    toSubscriptions = () => {
        this.setState({
            isSubscribe: true
        })
    };

    componentDidMount() {
        this.authUser();
        this.getSubscriptions();
        this.getUserSubscriptions();
        this.getProducts();
        this.timeNow();
    }

    authUser = () => {
        const token = Cookies.get('zplAntrATkn-99');
        this.setState({
            token
        });
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
        };
        axios.get(`${BASE_URL}user`, {
            headers: headers
        }).then(res => {
            this.setState({
                user: {
                    phone: res.data.phone,
                    email: res.data.email
                },
                userBday: res.data.bday,
                bdayVerified: res.data.bdayVerified,
                userRelatives: res.data.relatives,
                userName: {
                    name: res.data.name,
                    sname: res.data.sname
                },
                isAuth: true
            })
        }).catch(err => err);
    };

    changeOption = (orderId, optionId, productId, optionName = '', index, optI) => {
        let orders = [...this.state.orders];// eslint-disable-next-line
        let product = [...this.state.products].find(p => p.main.id === productId);// eslint-disable-next-line
        let option = product.products.find(o => o.id === optionId).amount[optI];// eslint-disable-next-line
        let options = orders.find(order => order.id === orderId).options;// eslint-disable-next-line
        let op = options.find(o => o.id === optionId).amount[index];

        if (!op) return;
        op.name = option.option;
        op.price = option.cost;
        orders.map(o => {
            //o.id = orderId;
            o.total = this.getTotal(o.id, o.price, o.peopleCount, o.time.start, o.time.end,
                o.options
                , o.childrenCount, o.productCount)
        });
        this.setState({
            orders,
            changeOption: true
        });
    };

    // редактировал
    // make changes , because don't removed options
    removeOption = (event, orderId, optionId, productId, optionName = '') => {
        event.preventDefault();
        const poolTime = this.state.poolTimeWork;
        const date = this.state.dayWeek;
        let orders = [...this.state.orders];// eslint-disable-next-line
        let product = [...this.state.products].find(p => p.main.id === productId);// eslint-disable-next-line
        let option = product.products.find(o => o.id === optionId);// eslint-disable-next-line
        let options = orders.find(order => order.id === orderId).options;// eslint-disable-next-line
        let count = orders.find(count => count).productCount;

        if (options.find(o => o.id === optionId)) {
            if (Array.isArray(option.amount)) {// eslint-disable-next-line
                let cOption = options.find(o => o.id === optionId).amount.find(o => o.name === optionName || o.name !== optionName);// eslint-disable-next-line;
                if (!cOption) {
                    return
                }// eslint-disable-next-line
                options.find(o => o.id === optionId).amount.splice(options.find(o => o.id === optionId).amount.length - 1, 1); // eslint-disable-next-line
                if (options.find(o => o.id === optionId).amount.length === 0) {// eslint-disable-next-line
                    options.splice(options.indexOf(options.find(o => o.id === optionId)), 1)// eslint-disable-next-line
                }
            } else {
                if (options.find(o => o.id === optionId).amount !== 0 && optionId !== '13') {
                    options.find(o => o.id === optionId).amount -= 1;
                }
                if (orders.find(o => o).peopleCount !== options.find(o => o.id === '13').amount && this.state.eveningPreferredProduct === false && optionId === '13') { // && optionId === '13'
                    //count -= 1;
                    options.find(o => o.id === optionId).amount -= 1;
                }
                // if (options.find(o => o.id === '13').amount === orders.find(o => o).peopleCount && this.state.eveningPreferredProduct === false && optionId === '13') {
                //     console.log("TYT NE NADA BLYA");
                //     //return false;
                // }
                // if (orders.find(count => count).productCount !== 0 && this.state.eveningPreferredProduct === true && optionId === '13') {
                //     count -= 1;
                // }
                // if(orders.find(count => count).productCount === 1 && this.state.eveningPreferredProduct === false && optionId === '13') {
                //     return false;
                // }
                if (options.find(o => o.id === optionId).amount === 0) {
                    options.splice(options.indexOf(options.find(o => o.id === optionId)), 1)
                }
            }
        }
        orders = orders.map(o => {// eslint-disable-next-line
            if (o.id === orderId) {
                o.price = this.getPrice(product, o.date);
                o.options = options;
                o.productCount = count;
                o.total = this.getTotal(o.id,
                    o.product.main.id === '05' && poolTime === o.product.main.evening_time || poolTime === o.product.main.day_time
                        ?
                        date.getDay() === 0 || date.getDay() === 6
                            ?
                            poolTime === o.product.main.day_time
                                ?
                                this.state.changePoolDiscount === true ? 1 : o.price - o.product.main.day_diff
                                :
                                poolTime === o.product.main.evening_time
                                    ?
                                    this.state.changePoolDiscount === true ? 1 : o.price - o.product.main.evening_diff
                                    :
                                    this.state.changePoolDiscount === true ? 1 : o.price
                            :
                            this.state.changePoolDiscount === true ? 1 : o.price - o.product.main.day_diff
                        :
                        o.price,
                    o.peopleCount, o.time.start, o.time.end, options, o.childrenCount, o.productCount
                );
            }
            return o;
        });
        this.setState({
            orders
        });
    };

    // редактировал
    addOption = (event, orderId, optionId, productId, optionName = '') => {
        event.preventDefault();
        const poolTime = this.state.poolTimeWork;
        const date = this.state.dayWeek;
        let orders = [...this.state.orders];// eslint-disable-next-line
        let product = [...this.state.products].find(p => p.main.id === productId);// eslint-disable-next-line
        let option = product.products.find(o => o.id === optionId);// eslint-disable-next-line
        let options = orders.find(order => order.id === orderId).options;// eslint-disable-next-line
        let count = orders.find(count => count).productCount;
        if (options.find(o => o.id === optionId)) {
            if (Array.isArray(option.amount)) {// eslint-disable-next-line
                let op = options.find(o => o.id === optionId).amount.find(o => o.name === optionName);
                options.find(o => o.id === optionId).amount.push({
                    name: optionName,
                    amount: 1,// eslint-disable-next-line
                    price: option.amount.find(o => o.option === optionName).cost
                })
            } else {
                options.find(o => o.id === optionId).amount += 1;
                // if (options.find(o => o).id === optionId) {
                //     orders.find(count => count).productCount += 1
                //     //options.find(o => o.id === optionId).amount += 1;
                // }
            }
        } else {
            if (Array.isArray(option.amount)) {
                options.push({
                    id: option.id,
                    amount: [{
                        name: optionName,// eslint-disable-next-line
                        price: option.amount.find(o => o.option === optionName).cost,
                        amount: 1
                    }],
                });
            } else {
                options.push({
                    id: option.id,
                    amount: 1,
                    price: option.cost
                });
            }
        }
        orders = orders.map(o => {// eslint-disable-next-line
            if (o.id === orderId) {
                o.price = this.getPrice(product, o.date);
                o.options = options;
                //o.productCount = count;
                o.total = this.getTotal(o.id,
                    o.product.main.id === '05' && poolTime === o.product.main.evening_time || poolTime === o.product.main.day_time
                        ?
                        date.getDay() === 0 || date.getDay() === 6
                            ?
                            poolTime === o.product.main.day_time
                                ?
                                this.state.changePoolDiscount === true ? 1 : o.price - o.product.main.day_diff
                                :
                                poolTime === o.product.main.evening_time
                                    ?
                                    this.state.changePoolDiscount === true ? 1 : o.price - o.product.main.evening_diff
                                    :
                                    this.state.changePoolDiscount === true ? 1 : o.price
                            :
                            this.state.changePoolDiscount === true ? 1 : o.price - o.product.main.day_diff
                        :
                        o.price,
                    o.peopleCount, o.time.start, o.time.end, options, o.childrenCount, o.productCount, //optionId
                );
            }
            return o;
        });
        this.setState({
            orders
        });
    };

    // редактировал
    // removed preferred options in orders (because doesn't work addOption())
    changeActiveProduct = (order, id, productOrder, orderProduct, products, index) => {
        const prodToChange = products.find(prod => {
            if (prod.main.id === id) {
                return prod
            }
        });
        this.setState({
            activeProductId: id,
            poolProduct: orderProduct,
            poolOrder: productOrder,
            secondActiveId: id,
            service: prodToChange
        });
        const idSubsc = this.state.subscriptionId;
        const changeSubsc = this.state.changeSubsc;
        let orders = [...this.state.orders];// eslint-disable-next-line
        const product = this.state.products.find(p => p.main.id === id);
        if (id === '03' || id === '04') {
            this.setState({
                visit: false
            })
        } else {
            this.setState({
                visit: true
            })
        }
        if (id !== '05') {
            this.setState({
                subscriptionChange: false,
                loadingSubscription: false
            })
        }
        if (product.products) {
            const preferred_options = product.products.filter(p => p.preferred);
            let count = orders.find(count => count).peopleCount;
            const options = preferred_options.map(o => {
                return {
                    id: o.id,
                    amount: changeSubsc === true ? 0 : count,
                    price: o.cost
                };
            });
            const time = {
                start: new Date(`${new Date().getFullYear()} ${new Date().getMonth() + 1} ${new Date().getDate()} ${orderProduct.time[new Date().getDay()].start}`),
                end: new Date(new Date(`${new Date().getFullYear()} ${new Date().getMonth() + 1} ${new Date().getDate()} ${orderProduct.time[new Date().getDay()].start}`).getTime() + 3600000)
            };
            // TODO return
            orders = orders.map(o => {
                const str = options.map(o => o.price);
                const result = str.reduce((sum, current) => (sum + current), 0);

                if (o.id === order) {
                    o.product = {
                        ...product
                    };
                    o.time = id === '03' || id === '04' || id === '02' ? o.time : time;
                    const ordersId = orders.map(o => o.product.main.id);
                    const findSomePool = (bool) => (
                        bool === '05'
                    );
                    const findSomeTrampoline = (bool) => (
                        bool === '01'
                    );
                    const withOutPool = (bool) => (
                        bool !== '05'
                    );
                    o.price = ordersId.some(findSomeTrampoline) && ordersId.some(findSomePool) && product.main.id === '01'
                        ?
                        this.getPrice(product, o.date) * 0.5
                        :
                        // product.main.id === '01'
                        //     ?
                        // this.getPrice(product, o.date) * 0.5
                        // :
                            this.getPrice(product, o.date)
                    ;
                    o.childrenCount = o.childrenCount;
                    o.options = options;  // changed options
                    // if (ordersId.some(findSomeTrampoline) && ordersId.some(findSomePool)) {
                    //     o.total = idSubsc !== null //&& id[0] === '05'
                    //         ?
                    //         this.getTotal(o.id, o.price, o.peopleCount, o.time.start, o.time.end, [], o.childrenCount, o.productCount)
                    //         :
                    //         id[0] === "05"
                    //             ?
                    //             this.getTotal(o.id, o.price + result, o.peopleCount, o.time.start, o.time.end, [], o.childrenCount, o.productCount)
                    //             :
                    //             product.main.id === '01'
                    //                 ?
                    //                 this.getTotal(o.id, o.price * 0.5, o.peopleCount, o.time.start, o.time.end, [], o.childrenCount, o.productCount)
                    //                 :
                    //                 this.getTotal(o.id, o.price + result, o.peopleCount, o.time.start, o.time.end, [], o.childrenCount, o.productCount)
                    // } else {
                    o.total = idSubsc !== null //&& id[0] === '05'
                        ?
                        this.getTotal(o.id, o.price, o.peopleCount, o.time.start,
                            o.time.end
                            , [], o.childrenCount, o.productCount)
                        :
                        id === "05"
                            ?
                            this.getTotal(o.id, o.price + result, o.peopleCount, o.time.start, o.time.end, [], o.childrenCount, o.productCount)
                            :
                            this.getTotal(o.id,
                                o.price //+ result
                                , o.peopleCount, o.time.start,
                                o.time.end
                                , [],
                                //o.price
                                0,
                                o.productCount)
                    //}
                }
                return o;
            });
        } else {
            orders = orders.map(o => {
                if (o.id === order) {
                    o.product = {
                        ...product
                    };
                    o.price = this.getPrice(product, o.date);
                    o.options = [];
                    o.total = id === "05"
                        ?
                        this.getTotal(o.id, o.price,
                            product.main.id === '03' || product.main.id === '04' ? 1 : o.peopleCount, o.time.start, o.time.end, [],
                            o.childrenCount,
                            o.productCount)
                        :
                        this.getTotal(o.id, o.price,
                            product.main.id === '03' || product.main.id === '04' ? 1 : o.peopleCount, o.time.start, o.time.end, [],
                            product.main.id === '03' || product.main.id === '04' ? 0 : o.childrenCount,
                            o.productCount);
                }
                return o
            });
        }
        const filter = orders.filter(o => {
            return o.product.main.id === '03' || o.product.main.id === '04'
        });
        const ordersId = orders.map(o => o.product.main.id);
        const findSomeTrampoline = (bool) => (
            bool === '01'
        );
        const findSomePool = (bool) => (
            bool === '05'
        );
        const withOutPool = (bool) => (
            bool !== '05'
        );

        if (orders.find(o => o.product.main.id === '03') || orders.find(o => o.product.main.id === '04')) {
            this.setState({
                orders: filter,
                visit: false
            });
        }
        if(orders.find(o => o.total === 60) && ordersId.some(withOutPool) && ordersId.some(findSomeTrampoline) ){
            orders.find(o => o.total === 60).total *= 2;
            if(orders.find(o => o.price === 60).price && ordersId.some(withOutPool)){
                orders.find(o => o.price === 60).price *= 2;
            }
        }
        if(orders.find(o => o.total === 120) && ordersId.some(findSomePool) && ordersId.some(findSomeTrampoline)){
            orders.find(o => o.total === 120).total *= 0.5;
            if(orders.find(o => o.price === 120).price && ordersId.some(findSomePool) && ordersId.some(findSomeTrampoline)){
                orders.find(o => o.price === 120).price *= 0.5;
            }
        }
        else {
            this.setState({
                orders,
                visit: true
            })
        }
    };

    getAvailableDates = (date, productId, order) => {
        let orders = [...this.state.orders];
        axios.get(`${BASE_URL}form?id=${productId}&date=${this.formatDate(date)}-01T00%3A00%3A00%2B0000`).then(res => {
            orders = orders.map(o => {
                if (o.id === order) {
                    o.product = {
                        ...res.data
                    };
                    o.price = this.getPrice(res.data, o.date);
                    o.total = this.getTotal(o.id, this.getPrice(res.data, o.date), o.peopleCount, o.time.start, o.time.end, o.options, o.childrenCount, o.productCount);
                }
                return o
            });
        })
    };

    changedPoolDiscount = (orderId, e, product) => {
        const orders = [...this.state.orders];
        const order = orders.find(o => o.id === orderId);
        const handleChange = this.state.changePoolDiscount;
        const loadingSubscription = this.state.loadingSubscription;
        if (order.id === orderId) {
            this.setState({
                changePoolDiscount: !handleChange,
                loadingSubscription: !loadingSubscription
            });
            order.total = this.getTotal(
                order.id,
                order.price = e.target.checked === true
                    ?
                    1
                    :
                    this.getPrice(product.product, new Date())
                ,
                order.peopleCount, order.time.start, order.time.end, order.options, order.childrenCount, order.productCount
            );
            this.setState({
                orders
            });
        }
    };

    changedDiscount = (orderId, e, product) => {
        const orders = [...this.state.orders];
        const order = orders.find(o => o.id === orderId);
        const handleChange = this.state.changeDiscount;
        if (order.id === orderId) {
            this.setState({
                changeDiscount: !handleChange,
            });
            //setTimeout(() => {
            order.total = this.getTotal(
                order.id,
                order.price = e.target.checked === true
                    ?
                    1
                    :
                    this.getPrice(product.product, new Date())
                ,
                order.peopleCount, order.time.start, order.time.end, order.options, order.childrenCount, order.productCount
            );
            this.setState({
                orders
            });
            //}, 100)
        }
    };

    changeDate = (order, date, orderProduct) => {
        this.setState({
            dateForSubscription: date,
            changeDateForPool: true
        });
        let orders = [...this.state.orders];
        // if (isAuth) {
        //     this.setState({
        //         changeDiscount: true
        //     })
        // }
        const preordered = orders[0].product.main.preordered;
        const preorderedDay = new Date(preordered) / (1000 * 60 * 60 * 24);
        this.setState({
            preordered: preorderedDay
        });
        let dateNow = new Date().getTime();
        let activeDate = date.getTime();
        let changeDate = activeDate - dateNow;
        if (changeDate > preordered) {
            if (this.state.activeProductId === '05') {
                this.setState({
                    changeActiveDate: false
                })
            } else {
                this.setState({
                    changeActiveDate: true
                });
            }
        } else if (changeDate <= preordered) {
            this.setState({
                changeActiveDate: false
            })
        } else if (this.state.activeProductId === '05') {
            this.setState({
                changeActiveDate: false
            })
        }
        const productId = orders.find(o => o.id === order).product.main.id;
        const day = new Date(date).getDate() < 10 ? '0' + new Date(date).getDate() : new Date(date).getDate();
        axios.get(`${BASE_URL}form?id=${productId}&time=${this.formatDate(date)}-${day}T00%3A00%3A00%2B0000`);
        const products = orders.map(o => o.product.main.id);
        let preferredOptions;
        const preferredProducts = orders.find(o => o.id === order).product.products;
        if (orders.find(o => o.id === order).product.products !== undefined) {
            preferredOptions = preferredProducts.find(o => o.preferred);
        }
        const findPool = (bool) => (
            bool === '05'
        );
        const findTrampoline = (bool) => (
            bool === '01'
        );
        const weeknd = date.getDay() === 6 || date.getDay() === 0;
        orders = orders.map(o => {
            if (o.id === order) {
                o.date = date;
                o.price =
                    products.some(findPool) && products.some(findTrampoline) && productId === '01'
                        ?
                        (1 * this.getPrice(o.product, date)) * 0.5
                        :
                        // orderProduct.evening_time === this.state.poolTimeWork && productId === '05'
                        //     ?
                        //     (1 * this.getPrice(o.product, date, orderProduct)) - orderProduct.day_diff
                        //     :
                        1 * this.getPrice(o.product, date, orderProduct);
                o.options =
                    productId === '05' && orderProduct.evening_time !== this.state.poolTimeWork
                        ?
                        [{
                            id: preferredOptions.id,
                            price: preferredOptions.cost,
                            amount: 1
                        }]
                        :
                        []
                ;
                //o.productCount = productId === '05' && orderProduct.evening_time !== this.state.poolTimeWork ? o.productCount : 0;
                o.time = {
                    start: new Date(`${new Date(date).getFullYear()} ${new Date(date).getMonth() + 1} ${new Date(date).getDate()} ${o.product.main.time[new Date(date).getDay()].start}`),
                    end: new Date(new Date(`${new Date(date).getFullYear()} ${new Date(date).getMonth() + 1} ${new Date(date).getDate()} ${o.product.main.time[new Date(date).getDay()].start}`).getTime() + 3600000)
                };
                o.total = this.getTotal(o.id,
                    products.some(findPool) && products.some(findTrampoline)
                        ?
                        productId === '01'
                            ?
                            (1 * this.getPrice(o.product, date)) * 0.5
                            :
                            1 * this.getPrice(o.product, date)
                        :
                        orderProduct.evening_time === this.state.poolTimeWork || orderProduct.day_time === this.state.poolTimeWork //&& productId === '05'
                            ?
                            (1 * this.getPrice(o.product, date, orderProduct)) - orderProduct.day_diff
                            :
                            weeknd && orderProduct.evening_time === this.state.poolTimeWork
                                ?
                                (1 * this.getPrice(o.product, date, orderProduct)) - orderProduct.evening_diff
                                :
                                1 * this.getPrice(o.product, date, orderProduct),
                    o.peopleCount, o.time.start, o.time.end, o.options, o.childrenCount, o.productCount, orderProduct, orderProduct.bdayDiscount, productId);
            }
            return o
        });
        this.setState({
            orders
        });
        const userBday = new Date(this.state.userBday);
        const orderDate = new Date(orders.map(o => o.date));
        if (userBday.getDate() === orderDate.getDate() && userBday.getMonth() + 1 === orderDate.getMonth() + 1) {
            this.setState({
                userDiscount: true
            })
        } else {
            this.setState({
                userDiscount: false,
                changePoolDiscount: false,
                changeDiscount: false
            })
        }
    };

    changeDateAndPeople = (order, date) => {
        let orders = [...this.state.orders];
        //const order = orders.find(o => o.id === orderId);
        //const preordered = orders[0].product.main.preordered;
        //let dateNow = new Date().getTime();
        //let activeDate = date.getTime();
        //const productId = orders.find(o => o.id === order).product.main.id;
        //const day = new Date(date).getDate() < 10 ? '0' + new Date(date).getDate() : new Date(date).getDate();
        // axios.get(`${BASE_URL}form?id=${productId}&time=${this.formatDate(date)}-${day}T00%3A00%3A00%2B0000&people=${}`);
        orders = orders.map(o => {
            if (o.id === order) {
                o.date = date;
                o.price = this.getPrice(o.product, date);
                o.time = {
                    start: new Date(`${new Date(date).getFullYear()} ${new Date(date).getMonth() + 1} ${new Date(date).getDate()} ${o.product.main.time[new Date(date).getDay()].start}`),
                    end: new Date(new Date(`${new Date(date).getFullYear()} ${new Date(date).getMonth() + 1} ${new Date(date).getDate()} ${o.product.main.time[new Date().getDay()].start}`).getTime() + 3600000)
                };
                o.total = this.getTotal(o.id, this.getPrice(o.product, date), o.peopleCount, o.time.start, o.time.end, o.options, o.childrenCount, o.productCount);
            }
            return o
        });
        this.setState({
            orders
        })
    };

    formatDate = (date) => {
        date = new Date(date);
        return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}`
    };

    timeNow = () => {
        const dateNow = new Date();
        this.setState({
            timeNow: dateNow
        })
    };

    changeTime = (orderId, key, time, product) => {
        time = parseInt(time);
        const orders = [...this.state.orders];// eslint-disable-next-line
        const order = orders.find(o => o.id === orderId);// eslint-disable-next-line
        const endTime = (order.time.end).getTime();
        const ordersId = orders.map(o => o.product.main.id);
        const findPool = (bool) => (
            bool === '05'
        );
        const findTrampoline = (bool) => (
            bool === '01'
        );

        order.time = key === 'start' ? {
            start: new Date(time),
            end: (new Date(time) >= new Date(endTime)) ? new Date(time + 3600000) : order.time.end
        } : {
            start: order.time.start,
            end: new Date(time)
        };

        order.total = this.getTotal(
            order.id,
            ordersId.some(findTrampoline) && ordersId.some(findPool)
                ?
                product.id === '01'
                    ?
                    order.price * 0.5
                    :
                    order.price
                :
                order.price,
            order.peopleCount,
            order.time.start,
            order.time.end,
            order.options,
            order.childrenCount,
            order.productCount
        );

        this.setState({
            orders
        });
    };

    // редактировал
    changePoolTime = (time, orderId, product) => {
        this.setState({
            poolTimeWork: time,
            //poolProduct: product
        });
        const peopleCount = parseInt(this.state.orders.map(o => o.peopleCount).join());
        const childrenCount = parseInt(this.state.orders.map(o => o.childrenCount).join());
        console.log(peopleCount, childrenCount);
        if (time === '10:00') {
            this.setState({
                dayPoolTime: time,
                eveningPreferredProduct: false,
                eveningPoolTime: null
            });
            this.state.orders.find(o => o.options.find(o => o.id === '13').amount = peopleCount + childrenCount);
        } else if (time === product.evening_time) {
            this.setState({
                eveningPoolTime: time,
                eveningPreferredProduct: true
            });
            this.state.orders.find(o => o.options.find(o => o.id === '13').amount = 0);
        } else if (time === product.day_time) {
            this.setState({
                dinnerPoolTime: time,
                eveningPreferredProduct: false
            });
            this.state.orders.find(o => o.options.find(o => o.id === '13').amount = peopleCount + childrenCount);
        }
        const orders = [...this.state.orders];
        const order = orders.find(o => o.id === orderId);
        const date = orders.find(o => o.id === orderId).date;
        const eveningDiff = product.evening_diff;
        const eveningTime = product.evening_time;
        const dayTime = product.day_time;
        const dayDiff = product.day_diff;

        let preferredOptions;
        const preferredProducts = orders.find(o => o.id === orderId).product.products;
        if (orders.find(o => o.id === orderId).product.products !== undefined) {
            preferredOptions = preferredProducts.find(o => o.preferred);
        }
        const opt = {
            id: preferredOptions.id,
            amount: 1,
            price: preferredOptions.cost
        };
        if (eveningTime === time) {
            order.total = this.getTotal(order.id,
                this.state.changePoolDiscount === true ? 1 : order.price - dayDiff,
                order.peopleCount, order.time.start, order.time.end,
                time === product.evening_time
                    ?
                    order.options.filter(o => o.id !== '13')
                    :
                    order.options
                , order.childrenCount, order.productCount = 0);
            //order.options = order.options.find(id => id.id === '13').amount = 0;
        }
        if (eveningTime === time && date.getDay() === 0 || date.getDay() === 6) {
            order.total = this.getTotal(order.id,
                this.state.changePoolDiscount === true ? 1 : order.price - eveningDiff,
                order.peopleCount, order.time.start, order.time.end,
                time === product.evening_time
                    ?
                    []
                    :
                    order.options
                , order.childrenCount, order.productCount)
        }
        if (dayTime === time) {
            order.total = this.getTotal(order.id,
                this.state.changePoolDiscount === true ? 1 : order.price - dayDiff,
                order.peopleCount, order.time.start, order.time.end,
                time === product.evening_time ? [] : order.options
                , order.childrenCount, order.productCount = order.peopleCount + order.childrenCount)
        }
        if (eveningTime !== time && dayTime !== time) {
            order.total = this.getTotal(order.id,
                //this.state.changeDateForPool === true ? order.price + dayDiff :
                this.state.changePoolDiscount === true ? 1 : order.price,
                order.peopleCount, order.time.start, order.time.end,
                time === product.evening_time ? [] : order.options
                , order.childrenCount, order.productCount = order.peopleCount + order.childrenCount);
        }
        if (eveningTime !== time && dayTime !== time && order.total === order.price) {
            order.total = this.getTotal(order.id,
                this.state.changePoolDiscount === true ? 1 : order.price + dayDiff
                , order.peopleCount, order.time.start, order.time.end,
                order.options
                , order.childrenCount, order.productCount = 1);
            order.options = [opt]
        }
        this.setState({
            orders,
        });
    };

    // редактировал
    getProducts = () => {
        this.setState({
            isLoading: true,
            checkboxRules: true
        });
        Promise.all(
            [...this.state.productsIds.map(id => axios.get(`${BASE_URL}form?id=${id}`))]
        ).then(res => {
            const data = res.reverse().map(r => r.data);
            data.filter(p => p.products).map(p => p.products.map(o => o.count = 0));

            const orders = [...this.state.orders];
            const orderProducts = data[0].products.filter(pref => pref.preferred === true);
            const preferredOptions = {
                id: orderProducts.map(id => id.id).join(),
                amount: parseInt(orderProducts.map(id => id.count).join()) + 1,
                price: parseInt(orderProducts.map(price => price.cost).join())
            };
            orders.push({
                product: {
                    ...data[0]
                },
                id: 0,
                total: (1 * this.getPrice(data[0], new Date())) + parseInt(orderProducts.map(price => price.cost).join()),
                date: new Date(),
                options: orderProducts === 0 ? [] : [preferredOptions],
                peopleCount: 1,
                childrenCount: 0,
                productCount: 1,
                bdayDiscount: data[0].main.bdayDiscount,
                price: this.getPrice(data[0], new Date()),
                time: {
                    start: new Date(`${new Date().getFullYear()} ${new Date().getMonth() + 1} ${new Date().getDate()} ${data[0].main.time[new Date().getDay()].start}`),
                    end: new Date(new Date(`${new Date().getFullYear()} ${new Date().getMonth() + 1} ${new Date().getDate()} ${data[0].main.time[new Date().getDay()].start}`).getTime() + 3600000)
                }
            });
            this.setState({
                products: [...data],
                orders,
                productsIdForDiscount: [data[0].main.id]
                // subscriptions
            });
            this.setState({
                isLoading: false
            });
        })

    };

    getUserSubscriptions = () => {
        const token = Cookies.get('zplAntrATkn-99');
        this.setState({
            isLoading: true
        });
        const headers = {
            //'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
        };
        axios.get(`${BASE_URL}user/subscriptions`, {
            headers: headers
        })
            .then(res => this.setState({
                userSubscriptions: res.data,
                isLoading: true,
                loadingSubscription: true
            }));
    };

    getSubscriptions = () => {
        this.setState({
            isLoading: true
        });
        axios.get(`${BASE_URL}subscriptions`, {
            headers: {
                Authorization: `Bearer ${this.state.token}`
            }
        })
            .then(res => this.setState({
                subscriptions: res.data,
                isLoading: true
            }));
    };

    // редактировал
    getPrice = (product, date, orderProduct) => {// eslint-disable-next-line
        let poolTime = this.state.poolTimeWork;
        this.setState({
            dayWeek: date
        });
        if (product.holidays.find(d => Number(d.split('-')[1]) === date.getDate()) && product.main.id !== "05") {
            return product.main.cost[7]
        }
        if (product.holidays.find(d => Number(d.split('-')[1]) !== date.getDate()) && product.main.id !== "05") {
            return product.main.cost[date.getDay()]
        }
        if (product.holidays.find(d => Number(d.split('-')[1]) === date.getDate()) && product.main.id === '05') {
            return product.main.cost[7] //+ product.main.evening_diff
        }
        if (date.getDay() === 0 && product.main.id === '05') { // && product.main.evening_time !== poolTime
            return product.main.cost[0]
        }
        if (date.getDay() === 6 && product.main.id === '05') { //&& product.main.evening_time !== poolTime
            return product.main.cost[6]
        } else {
            return product.main.cost[date.getDay()]
        }
    };

    // редактировал
    peoplePlus = (orderId, key, product) => {
        const orders = [...this.state.orders];// eslint-disable-next-line
        const order = orders.find(o => o.id === orderId);
        //const product = this.state.products.find(p => p.main.id === id);
        const reserve = this.state.reserve;
        const poolTime = this.state.poolTimeWork;
        const date = this.state.dayWeek;
        const ordersId = orders.map(o => o.product.main.id);
        const findSomePool = (bool) => (
            bool === '05'
        );
        const findSomeTrampoline = (bool) => (
            bool === '01'
        );
        if (!reserve) {
            order.peopleCount += 1;
            // order.productCount +=
            //     this.state.eveningPreferredProduct === false
            //         ?
            //         1
            //         :
            //         null
            // ;
            order.total = this.getTotal(
                order.id,
                order.product.main.id === '05' && poolTime === order.product.main.evening_time || poolTime === order.product.main.day_time
                    ?
                    date.getDay() === 0 || date.getDay() === 6
                        ?
                        poolTime === order.product.main.day_time
                            ?
                            order.price - order.product.main.day_diff
                            :
                            poolTime === order.product.main.evening_time
                                ?
                                order.price - order.product.main.evening_diff
                                :
                                order.price
                        :
                        order.price - order.product.main.day_diff
                    :
                    // ordersId.some(findSomeTrampoline) && ordersId.some(findSomePool)
                    //     ?
                    //     order.product.main.id === '01'
                    //         ?
                    //         order.price * 0.5
                    //         :
                    //         order.product.main.id === '05'
                    //             ?
                    //             order.price + order.product.products.find(o => o.preferred).cost
                    //             :
                    //             order.price
                    //     :
                    order.price,
                order.peopleCount,
                order.time.start,
                order.time.end,
                order.options,
                order.childrenCount,
                order.productCount,
                key
            );
            order.price = this.state.changePoolDiscount === true && order.peopleCount === 1 ? 1 : this.getPrice(product.product, new Date());
            if(this.state.userDiscount === true && order.peopleCount > 1) {
                this.setState({
                    changePoolDiscount: false,
                    changeDiscount: false,
                    userDiscount: false,
                    loadingSubscription: false
                });
                order.total = this.getTotal(order.id, order.price, order.peopleCount, order.time.start, order.time.end, order.options, order.childrenCount, order.productCount)
            }
            else if(order.peopleCount > 1 && this.state.loadingSubscription === true) {
                this.setState({
                    loadingSubscription: false
                })
            }
            this.setState({
                orders
            });
        } else if (reserve) {
            order.peopleCount += 1;
            order.total = this.getTotal(order.id, order.price, order.peopleCount, order.time.start, order.time.end, order.options, order.childrenCount, order.productCount);
            // poolTime === poolSericeTime ? order.price - poolSericeDiff :
            this.setState({
                orders
            });
        }
    };

    // редактировал
    childrenPlus = (orderId, key, product) => {
        const orders = [...this.state.orders];// eslint-disable-next-line
        const order = orders.find(o => o.id === orderId);
        const reserve = this.state.reserve;
        const poolTime = this.state.poolTimeWork;
        const date = this.state.dayWeek;
        //order.peopleCount += 1;
        if (!reserve) {
            order.childrenCount += 1;
            // order.productCount +=
            //     this.state.eveningPreferredProduct === false
            //         ?
            //         1
            //         :
            //         null
            // ;
            order.total = this.getTotal(
                order.id,
                order.product.main.id === '05' && poolTime === order.product.main.evening_time || poolTime === order.product.main.day_time
                    ?
                    date.getDay() === 0 || date.getDay() === 6
                        ?
                        poolTime === order.product.main.day_time
                            ?
                            order.price - order.product.main.day_diff
                            :
                            poolTime === order.product.main.evening_time
                                ?
                                order.price - order.product.main.evening_diff
                                :
                                order.price
                        :
                        order.price - order.product.main.day_diff
                    :
                    order.price,
                order.peopleCount,
                order.time.start,
                order.time.end,
                order.options,
                order.childrenCount,
                order.productCount,
                key
            );
            order.price = this.state.changePoolDiscount === true && order.childrenCount === 1 ? this.getPrice(product.product, new Date()) : this.getPrice(product.product, new Date());
            if(this.state.userDiscount === true && order.childrenCount >= 1) {
                this.setState({
                    changePoolDiscount: false,
                    userDiscount: false,
                    loadingSubscription: false
                });
                order.total = this.getTotal(order.id, order.price, order.peopleCount, order.time.start, order.time.end, order.options, order.childrenCount, order.productCount)
            }
            else if(this.state.loadingSubscription === true && order.childrenCount >= 1) {
                this.setState({
                    loadingSubscription: false
                })
            }
            this.setState({
                orders
            });
        } else if (reserve) {
            order.childrenCount += 1;
            order.total = this.getTotal(order.id, order.price, order.peopleCount, order.time.start, order.time.end, order.options, order.childrenCount, order.productCount);
            // poolTime === poolSericeTime ? order.price - poolSericeDiff :
            this.setState({
                orders
            });
        }
    };

    // редактировал
    peopleMinus = (orderId, key) => {
        const orders = [...this.state.orders];// eslint-disable-next-line
        const order = orders.find(o => o.id === orderId);
        const reserve = this.state.reserve;
        const poolTime = this.state.poolTimeWork;
        const date = this.state.dayWeek;
        const userBday = new Date(this.state.userBday);
        const userVerified = this.state.bdayVerified;
        const ordersDate = new Date(orders.map(o => o.date));
        const ordersId = orders.map(o => o.product.main.id);
        const findSomePool = (bool) => (
            bool === '05'
        );
        const findSomeTrampoline = (bool) => (
            bool === '01'
        );
        if (!reserve) {
            if (order.peopleCount === 1) {
                return false;
            }
            order.peopleCount -= 1;
            // order.productCount -=
            //     this.state.eveningPreferredProduct === false
            //         ?
            //         order.productCount === 1
            //             ?
            //             null
            //             :
            //             1
            //         :
            //         null
            // ;
            order.total = this.getTotal(
                order.id,
                order.product.main.id === '05' && poolTime === order.product.main.evening_time || poolTime === order.product.main.day_time
                    ?
                    date.getDay() === 0 || date.getDay() === 6
                        ?
                        poolTime === order.product.main.day_time
                            ?
                            order.price - order.product.main.day_diff
                            :
                            poolTime === order.product.main.evening_time
                                ?
                                order.price - order.product.main.evening_diff
                                :
                                order.price
                        :
                        order.price - order.product.main.day_diff
                    :
                    // ordersId.some(findSomeTrampoline) && ordersId.some(findSomePool)
                    //     ?
                    //     order.product.main.id === '01'
                    //         ?
                    //         order.price * 0.5
                    //         :
                    //         order.price
                    //     :
                    order.price,
                order.peopleCount,
                order.time.start,
                order.time.end,
                order.options,
                order.childrenCount,
                order.productCount,
                key
            );
            if(order.peopleCount < 2 && userVerified === true
                && userBday.getDate() === ordersDate.getDate() && userBday.getMonth() + 1 === ordersDate.getMonth() + 1) {
                this.setState({
                    userDiscount: true,
                    loadingSubscription: true
                });
            }
            else if(order.peopleCount < 2 && this.state.loadingSubscription === false) {
                this.setState({
                    loadingSubscription: true
                })
            }
            this.setState({
                orders
            })
        } else if (reserve) {
            if (order.peopleCount === 1) {
                return false;
            } else {
                order.peopleCount -= 1;
                order.total = this.getTotal(order.id, order.price, order.peopleCount, order.time.start, order.time.end, order.options);
                this.setState({
                    orders
                })
            }
        }
    };

    // редактировал
    childrenMinus = (orderId, key) => {
        const orders = [...this.state.orders];// eslint-disable-next-line
        const order = orders.find(o => o.id === orderId);
        const reserve = this.state.reserve;
        const poolTime = this.state.poolTimeWork;
        const date = this.state.dayWeek;
        const userBday = new Date(this.state.userBday);
        const ordersDate = new Date(orders.map(o => o.date));
        const userVerified = this.state.bdayVerified;
        if (!reserve) {
            if (order.childrenCount !== 0) {
                order.childrenCount -= 1;
            }
            // if (order.productCount !== order.peopleCount) {
            //     order.productCount -=
            //         this.state.eveningPreferredProduct === false
            //             ?
            //             order.productCount === 1
            //                 ?
            //                 null
            //                 :
            //                 1
            //             :
            //             null
            //     ;
            // }
            order.total = this.getTotal(
                order.id,
                order.product.main.id === '05' && poolTime === order.product.main.evening_time || poolTime === order.product.main.day_time
                    ?
                    date.getDay() === 0 || date.getDay() === 6
                        ?
                        poolTime === order.product.main.day_time
                            ?
                            order.price - order.product.main.day_diff
                            :
                            poolTime === order.product.main.evening_time
                                ?
                                order.price - order.product.main.evening_diff
                                :
                                order.price
                        :
                        order.price - order.product.main.day_diff
                    :
                    order.price,
                order.peopleCount,
                order.time.start,
                order.time.end,
                order.options,
                order.childrenCount,
                order.productCount,
                key
            );
            if(order.childrenCount < 1 && userVerified === true
                && userBday.getDate() === ordersDate.getDate() && userBday.getMonth() + 1 === ordersDate.getMonth() + 1) {
                this.setState({
                    userDiscount: true,
                    loadingSubscription: true
                });
            }
            else if(this.state.loadingSubscription === false && order.childrenCount < 1) {
                this.setState({
                    loadingSubscription: true
                })
            }
            this.setState({
                orders
            })
        } else if (reserve) {
            if (order.childrenCount === 1) {
                return false;
            } else {
                order.childrenCount -= 1;
                order.total = this.getTotal(order.id, order.price, order.peopleCount, order.time.start, order.time.end, order.options, order.childrenCount);
                this.setState({
                    orders
                })
            }
        }
    };

    // редактировал
    addOrderItem = () => {
        const orders = [...this.state.orders];
        const product = this.state.products[0];
        this.setState({
            changeSecondOrder: true
        });
        const productId = this.state.products[0].main.id;
        const getOptions = product.products.map(o => o);
        const poolPreferred = getOptions.find(p => p.preferred === true);
        const poolOption = {
            id: poolPreferred.id,
            price: poolPreferred.cost,
            amount: 1
        };
        const ordersId = orders.map(o => o.product.main.id);
        const findDiscount = (bool) => (
            bool === '01'
        );
        if (ordersId.some(findDiscount) && productId === '05') {
            orders.find(o => o.product.main.id === '01').total *= 0.5;
            if (orders.find(o => o.product.main.id === '01').total < 60) {
                orders.find(o => o.product.main.id === '01').total *= 2;
            }
        }
        orders.push({
            product: {
                ...this.state.products[0]
            },
            id: Math.floor(Math.random() * 1000),
            total:
                (1 * this.getPrice(this.state.products[0], new Date(), [], poolPreferred.cost)) + poolPreferred.cost
            ,
            date: new Date(),
            options:
                productId === '05'
                    ?
                    [poolOption]
                    :
                    [],
            peopleCount: 1,
            childrenCount: 0,
            productCount: 1,
            price: this.getPrice(this.state.products[0], new Date()),
            time: {
                start: new Date(`${new Date().getFullYear()} ${new Date().getMonth() + 1} ${new Date().getDate()} ${this.state.products[0].main.time[new Date().getDay()].start}`),
                end: new Date(new Date(`${new Date().getFullYear()} ${new Date().getMonth() + 1} ${new Date().getDate()} ${this.state.products[0].main.time[new Date().getDay()].start}`).getTime() + 3600000)
            }
        });
        if (ordersId.some(findDiscount) && productId === '05') {
            orders.find(o => o.product.main.id === "01").price *= 0.5;
            if (orders.find(o => o.product.main.id === "01").price < 60) {
                orders.find(o => o.product.main.id === "01").price *= 2
            }
        }
        if (orders.map(o => o.product.main.id === '05' && this.state.userDiscount === true)) {
            this.setState({
                changePoolDiscount: false
            })
        }
        if(orders.length > 1) {
            this.setState({
                userDiscount: false
            })
        }
        this.setState({
            orders
        });
    };

    removeOrder = (orderId) => {
        const orders = [...this.state.orders];
        this.setState({
            changeSecondOrder: false
        });
        orders.splice(orders.indexOf(orders.find(o => {// eslint-disable-next-line
            return o.id === orderId
        })), 1);
        const findTrampoline = orders.find(o => o).product.main.id === '01';
        const userBday = new Date(this.state.userBday);
        const ordersDate = new Date(orders.map(o => o.date));
        const userVerified = this.state.bdayVerified;
        const discountForUser = userBday.getDate() === ordersDate.getDate() && userBday.getMonth() + 1 === ordersDate.getMonth() + 1;
        if (orders.length === 1 && findTrampoline === true && orders.find(o => o.product.main.id === '01').price <= 60) {
            orders.find(o => o.product.main.id === '01').price = 120;
            orders.find(o => o.product.main.id === '01').total = 120;
            this.setState({
                orders,
                secondActiveId: null,
                removeDiscountTrampoline: true
            })
        } else {
            this.setState({
                orders,
                secondActiveId: null,
                removeDiscountTrampoline: false
            });
        }
        if(orders.length === 1 && discountForUser) {
            this.setState({
                userDiscount: true
            })
        }
    };

    // редактировал
    getTotal = (orderId, orderPrice, peopleCount, timeStart, timeEnd, options, childrenCount, productCount, key) => {
        let total = 0;
        const order = this.state.orders.find(o => o.id === orderId);
        const orders = [...this.state.orders];
        const serviceID = orders.map(discount => discount.product.main.id);

        //single time
        const duration = timeEnd.getTime() - timeStart.getTime();
        const multiplier = duration / 3600000;
        //(duration / (1000 * 60 * 60)) % 24
        this.setState({
            multiplier
        });

        total = multiplier * orderPrice * (peopleCount + childrenCount);
        options.map(option => {
            if (Array.isArray(option.amount)) {
                option.amount.map(opt => {
                    total += opt.price;
                });
            } else if (option !== null) {
                if(option.id === '13') {
                    if(key === 'peoplePlus' || key === 'childrenPlus') {
                        option.amount += 1;
                    }
                    if(key === 'childrenMinus' && option.amount > peopleCount && childrenCount !== 0){
                        option.amount -= 1;
                    }
                    if(key === 'childrenMinus' && option.amount === peopleCount && childrenCount === 0) {
                        //return false;
                    }
                    if(key === 'peopleMinus' && option.amount > peopleCount) {
                        option.amount -= 1;
                    }
                    else if(key === 'peopleMinus' && option.amount === peopleCount) {
                        return false;
                    }
                }
                    total += option.price * option.amount;
               //  if (order.product.products.find(id => id.id === option.id).id === option.id) {
               //      total += option.id !== '13' ? option.price * option.amount : option.price * productCount;
               //      //total += option.price * option.amount;
               //  }
               //  // if (order.product.products.find(id => id).id === option.id) {
               //  //     total += option.price * option.amount;
               //  //     //total += options.find(id => id.id === '11').price * options.find(id => id.id === '11').amount;
               //  // }
               //  // if(orderProduct === '16') {
               //  //     console.log('16 !!!', options.find(id => id.id === '16').price * options.find(id => id.id === '16').amount);
               //  //     total += options.find(id => id.id === '16').price * options.find(id => id.id === '16').amount
               //  // }
               //  else {
               //      total +=
               //          this.state.eveningPreferredProduct === false && this.state.changeOption === false
               //              ?
               //              option.price * productCount
               //              :
               //              option.price * productCount
               //      ;
               //  }
            }
        });
        this.setState({
            total,
            poolPrice: orderPrice,
        });
        return total
    };

    // редактировал
    // add order by type
    doOrder = () => {
        const orders = [...this.state.orders];
        const idService = orders.map(id => id.product.main);
        //return console.log('ID', idService.find(id => id.id) === '05');
        const idSubscription = this.state.subscriptionId;
        //const {dayPoolTime, eveningPoolTime} = this.state;
        const request = [];
        const requestPeople = [];
        const id = [];
        //const serviceId = this.state.activeProductId;
        const time = this.state.timeNow;
        const orderDate = orders.map(o => o.date.getDate());
        const t = orders.map(o => o.time.start.getHours()).join();
        const dateDate = parseInt(orderDate.join());
        if (idService.find(id => id.id === '02')) {
            console.log('@@@@')
        }

        if (new Date().getDate() === dateDate) {
            // if (idService.find(id => id.id === '02')) {
            //     return console.log('yes')
            // } else
            const ordersId = orders.map(o => o.product.main.id);
            const findSomeTrampoline = (bool) => (
                bool !== '01'
            );
            const findSomePool = (bool) => (
                bool === '05'
            );
            if ((time.getHours() + 1 > parseInt(t)) && ordersId === '01' && (!ordersId.some(findSomeTrampoline) && !ordersId.some(findSomePool))){ //&& idService.find(id => id.id !== '05')) {   // serviceId !== '05'
                this.setState({
                    failOrder: true
                });
                setTimeout(() => this.setState({failOrder: false}), 3000)
            } else {
                orders.map(order => {
                    id.push(order.product.main.id);
                    const products = [];// eslint-disable-next-line
                    order.options.map(opt => {
                        if (Array.isArray(opt.amount)) {
                            let option = {
                                id: opt.id,
                                amount: []
                            };// eslint-disable-next-line
                            opt.amount.map(o => {// eslint-disable-next-line
                                let optn = option.amount.find(op => op.option === o.name);
                                if (!optn) {
                                    option.amount.push({
                                        option: o.name,
                                        amount: 1
                                    })
                                } else {
                                    optn.amount += 1
                                }
                            });
                            products.push(option);
                        } else {
                            let child = order.options.find(id => id.id === opt.id).id === '16';
                            let noPreferProd = order.options.find(id => id.id === opt.id).id === '11';
                            products.push({
                                id: opt.id,
                                // // amount: this.state.eveningPreferredProduct === false
                                // //     ?
                                // //     order.product.products.find(id => id).id === opt.id ? opt.amount : orders.find(count => count).productCount
                                // //     :
                                // //     order.product.products.find(id => id).id === opt.id ? opt.amount : orders.find(count => count).productCount //- 1
                                // amount: child || noPreferProd ? opt.amount : orders.find(count => count).productCount
                                amount: opt.amount
                            });
                        }
                    });
                    if (idService.find(id => id.id === '05') && this.state.subscriptionChange === true) {
                        request.push({
                            id: order.product.main.id,// eslint-disable-next-line
                            start: this.state.eveningPoolTime === order.product.main.evening_time ? `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.product.main.evening_time}:00+0000`
                                : this.state.dayPoolTime === '10:00' ? `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T10:00:00+0000`
                                    :
                                    `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T10:00:00+0000`,
                            //start: `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.time.start.getHours()}:${order.time.start.getMinutes() === 0 ? '00' : '30'}:00+0000`,// eslint-disable-next-line
                            end: `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T20:00:00+0000`,
                            people: order.peopleCount + order.childrenCount,
                            products: products
                        });
                    } else if (idService.find(id => id.id === '05') && this.state.subscriptionChange === false) {
                        request.push({
                            id: order.product.main.id,// eslint-disable-next-line
                            start: this.state.eveningPoolTime === order.product.main.evening_time && this.state.dinnerPoolTime === null
                                ?
                                `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.product.main.evening_time}:00+0000`
                                :
                                this.state.dinnerPoolTime === order.product.main.day_time
                                    ?
                                    `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.product.main.day_time}:00+0000`
                                    :
                                    `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T10:00:00+0000`,
                            //start: `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.time.start.getHours()}:${order.time.start.getMinutes() === 0 ? '00' : '30'}:00+0000`,// eslint-disable-next-line
                            end: `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T20:00:00+0000`,
                            people: order.peopleCount + order.childrenCount,
                            products: products
                        });
                    } else if (idService.find(id => id.id !== '05')) {
                        request.push({
                            id: order.product.main.id,// eslint-disable-next-line
                            start: `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.time.start.getHours()}:${order.time.start.getMinutes() === 0 ? '00' : '30'}:00+0000`,// eslint-disable-next-line
                            end: `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.time.end.getHours()}:${order.time.end.getMinutes() === 0 ? '00' : '30'}:00+0000`,
                            people: order.peopleCount + order.childrenCount,
                            products: products
                        });
                    }
                });
                if (this.state.isAuth) {
                    if (idService.find(id => id.id === '05') && idSubscription !== null) {  //id[0] === '05'
                        requestPeople.push({
                            ...this.state.user,
                            type: "subscription",
                            subscription: idSubscription
                        });
                    } else {
                        requestPeople.push({
                            ...this.state.user,
                            type: "payment"
                        });
                    }
                }
                const data = {
                    info:
                        {
                            phone: this.state.unathPhone,
                            email: this.state.unathEmail,
                            type: "payment"
                        },
                    //body: this.state.changeSecondOrder === true ? [request] : request
                    body: request
                };
                const body = {
                    info: requestPeople[0],
                    body: this.state.changeSecondOrder === true
                        ?
                        idService.find(id => id.id === '05') && this.state.subscriptionChange === true ? request : request   //id[0] === '05'
                        :
                        idService.find(id => id.id === '05') && this.state.subscriptionChange === true ? request : request  //id[0] === '05'
                };
                fetch(`${BASE_URL}form/submit`, {
                    method: "POST",
                    body: this.state.buyOrder === true ? JSON.stringify(data) : JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`
                    }
                }).then(res => {
                    if (res.status === 303) {
                        this.setState({
                            failOrder: true
                        });
                        setTimeout(() => {
                            this.setState({
                                failOrder: false
                            })
                        }, 2000);
                    } else {
                        res.json().then(data => {
                            switch (res.status) {
                                case 200:
                                    if (idService.find(id => id.id === '05') && this.state.subscriptionChange === true) {  //id[0] === '05'
                                        this.setState({
                                            subscriptionChange: false,
                                            orderGood: true
                                        });
                                        setTimeout(() => this.setState({
                                            orderGood: false
                                        }), 3000)
                                    } else {
                                        this.setState({
                                            isPayForm: true,
                                            payForm: data.button,
                                            totalPriceOrder: data.total
                                        });
                                    }
                                    break;
                                case 400:
                                    console.log('400');
                                    this.setState({
                                        // isAuth: true,
                                        // isAuthShown: true
                                    });
                                    break;
                                default:
                                    alert("Ошибка заказа!");
                                    return res;
                            }
                        });
                    }
                }).catch(e => {
                        console.log(e);
                        this.setState({
                            failOrder: true
                        })
                    }
                )
            }
        } else {
            orders.map(order => {
                id.push(order.product.main.id);
                const products = [];// eslint-disable-next-line
                order.options.map(opt => {
                    if (Array.isArray(opt.amount)) {
                        let option = {
                            id: opt.id,
                            amount: []
                        };// eslint-disable-next-line
                        opt.amount.map(o => {// eslint-disable-next-line
                            let optn = option.amount.find(op => op.option === o.name);
                            if (!optn) {
                                option.amount.push({
                                    option: o.name,
                                    amount: 1
                                })
                            } else {
                                optn.amount += 1
                            }
                        });
                        products.push(option);
                    } else {
                        let child = order.options.find(id => id.id === opt.id).id === '16';
                        let noPreferProd = order.options.find(id => id.id === opt.id).id === '11';
                        products.push({
                            id: opt.id,
                            amount: opt.amount
                            // amount: child || noPreferProd
                            //     ?
                            //     opt.amount
                            //     :
                            //     this.state.eveningPreferredProduct === false
                            //         ?
                            //         orders.find(count => count).productCount
                            //         :
                            //         orders.find(count => count).productCount //-1
                            // // amount: this.state.eveningPreferredProduct === false
                            // //     ?
                            // //     //order.product.products.find(id => id).id !== opt.id ? order.productCount * opt.amount : opt.amount
                            // //     opt.amount
                            // //     :
                            // //     order.product.products.find(id => id).id !== opt.id ? (order.productCount - 1) * opt.amount : opt.amount
                        });
                    }
                });

                if (order.product.main.id === '05' && this.state.subscriptionChange === true) {// id[0] === '05'
                    request.push({
                        id: order.product.main.id,// eslint-disable-next-line
                        start: this.state.eveningPoolTime === order.product.main.evening_time ? `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.product.main.evening_time}:00+0000`
                            : this.state.dayPoolTime === '10:00' ? `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T10:00:00+0000`
                                :
                                `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T10:00:00+0000`,
                        //start: `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.time.start.getHours()}:${order.time.start.getMinutes() === 0 ? '00' : '30'}:00+0000`,// eslint-disable-next-line
                        end: `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T20:00:00+0000`,
                        people: order.peopleCount + order.childrenCount,
                        products: products
                    });
                } else if (order.product.main.id === '05' && this.state.subscriptionChange === false) {//id[0] === '05'
                    request.push({
                        id: order.product.main.id,// eslint-disable-next-line
                        start: this.state.eveningPoolTime === order.product.main.evening_time
                            ?
                            `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.product.main.evening_time}:00+0000`
                            :
                            order.product.main.id !== '05'
                                ?
                                `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.time.start.getHours()}:${order.time.start.getMinutes() === 0 ? '00' : '30'}:00+0000`
                                :
                                this.state.dinnerPoolTime === order.product.main.day_time
                                    ?
                                    `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.product.main.day_time}:00+0000`
                                    :
                                    `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T10:00:00+0000`,
                        end: order.product.main.id !== '05'
                            //idService.find(id => id.id !== '05')
                            ? `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.time.end.getHours()}:${order.time.end.getMinutes() === 0 ? '00' : '30'}:00+0000`
                            : `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T20:00:00+0000`,

                        people: order.peopleCount + order.childrenCount,
                        products: products
                    });
                } else if (order.product.main.id !== '05') {
                    const chillDay = order.time.start.getDay() === 0 || order.time.start.getDay() === 6 || order.time.start.getDay() === 7;
                    request.push({
                        id: order.product.main.id,// eslint-disable-next-line
                        start: `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.time.start.getHours()}:${order.time.start.getMinutes() === 0 ? '00' : '30'}:00+0000`,// eslint-disable-next-line
                        end: order.product.main.id === '01' && !chillDay
                            ?
                            `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T20:00:00+0000`
                            :
                            order.product.main.id === '01' && chillDay
                                ?
                                `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T21:00:00+0000`
                                :

                                `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.time.end.getHours()}:${order.time.end.getMinutes() === 0 ? '00' : '30'}:00+0000`,
                        people: order.peopleCount + order.childrenCount,
                        products: products
                    });
                }

                if (this.state.isAuth) {
                    if (order.product.main.id && idSubscription !== null) {
                        requestPeople.push({
                            ...this.state.user,
                            type: "subscription",
                            subscription: idSubscription
                        });
                    } else {
                        requestPeople.push({
                            ...this.state.user,
                            type: "payment"
                        });
                    }
                }
            });
            const data = {
                info: {
                    phone: this.state.unathPhone,
                    email: this.state.unathEmail,
                    type: "payment"
                },
                body: request
            };
            const body = {
                info: requestPeople[0],
                body: idService.find(id => id.id === '05') && this.state.subscriptionChange === true ? request : request
            };
            fetch(`${BASE_URL}form/submit`, {
                method: "POST",
                body: this.state.buyOrder === true ? JSON.stringify(data) : JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.token}`
                }
            }).then(res => {
                this.setState({
                    changeSecondOrder: false,
                    dayPoolTime: null,
                    eveningPoolTime: null
                });
                if (res.status === 303) {
                    this.setState({
                        failOrder: true
                    });
                    setTimeout(() => {
                        this.setState({
                            failOrder: false
                        })
                    }, 2000);
                } else {
                    res.json().then(data => {
                        switch (res.status) {
                            case 200:
                                if (idService.find(id => id.id === '05') && this.state.subscriptionChange === true) {
                                    this.setState({
                                        subscriptionChange: false,
                                        orderGood: true
                                    });
                                    setTimeout(() => this.setState({
                                        orderGood: false
                                    }), 3000)
                                } else {
                                    this.setState({
                                        isPayForm: true,
                                        payForm: data.button,
                                        totalPriceOrder: data.total
                                    });
                                }
                                break;
                            case 303:
                                console.log("fail");
                                break;
                            case 400:
                                console.log('400');
                                this.setState({
                                    // isAuth: true,
                                    // isAuthShown: true
                                });
                                break;
                            default:
                                alert("Ошибка заказа!");
                                return res;
                        }
                    });
                }
            }).catch(e => {
                    console.log(e);
                    this.setState({
                        failOrder: true
                    })
                }
            )
        }
    };

    doOrderReserve = () => {
        const orders = [...this.state.orders];
        const token = Cookies.get('zplAntrATkn-99');
        const request = [];
        const id = [];
        const subscription = this.state.subscriptionId;

        orders.map(order => {
            id.push(order.product.main.id);
            if (id[0] === '03' || id[0] === '04') {
                request.push({
                    id: order.product.main.id,
                    start: `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.time.start.getHours()}:${order.time.start.getMinutes() === 0 ? '00' : '30'}:00+0000`,// eslint-disable-next-line
                    end: `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.time.end.getHours()}:${order.time.end.getMinutes() === 0 ? '00' : '30'}:00+0000`,
                });
            } else {
                request.push({
                    id: order.product.main.id,
                    start: `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.time.start.getHours()}:${order.time.start.getMinutes() === 0 ? '00' : '30'}:00+0000`,// eslint-disable-next-line
                    end: `${this.formatDate(order.date)}-${order.date.getDate() < 10 ? '0' + order.date.getDate() : order.date.getDate()}T${order.time.end.getHours()}:${order.time.end.getMinutes() === 0 ? '00' : '30'}:00+0000`,
                    people: order.peopleCount + order.childrenCount
                });
            }
            if (this.state.isAuth) {
                if (id[0] === '05' && subscription !== null) {
                    request.push({
                        ...this.state.user,
                        type: 'subscription',
                        subscription: subscription
                    });
                } else {
                    request.push({
                        ...this.state.user,
                        type: 'payment'
                    });
                }
            } else if (this.state.buyOrder) {
                //this.setState({isEnterCred: true});
                request.push({
                    phone: this.state.unathPhone,
                    email: this.state.unathEmail,
                    type: "payment",
                })
            }
            const data = {
                info: {
                    phone: this.state.unathPhone,
                    email: this.state.unathEmail,
                    type: "payment"
                },
                body: [request[0]]
            };
            const body = {
                info: request[1],
                body: [request[0]]
            };
            fetch(`${BASE_URL}form/submit`, {
                method: "POST",
                body: this.state.buyOrder ? JSON.stringify(data) : JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => {
                if (res.status === 303) {
                    this.setState({
                        failOrder: true
                    });
                    setTimeout(() => {
                        this.setState({
                            failOrder: false
                        })
                    }, 2000);
                } else {
                    res.json().then(data => {
                        switch (res.status) {
                            case 200:
                                this.setState({
                                    isPayForm: true,
                                    payForm: data.button,
                                    totalPriceOrder: data.total
                                });
                                break;
                            case 400:
                                this.setState({
                                    // isAuth: true,
                                    // isAuthShown: true
                                });
                                break;
                            default:
                                alert("Ошибка заказа!");
                                return res;
                        }
                    })
                }
            }).catch(e => console.log(e))
        })
    };

    doOrderSubscription = (order) => {
        fetch(`${BASE_URL}subscriptions/buy`, {
            headers: {
                Authorization: `Bearer ${this.state.token}`,
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({body: [...order]}),
            method: "POST"
        })
            .then(res => res.json())
            .then(res => {
                this.setState({
                    payForm: res.button,
                    isPayForm: true,
                    checkoutObject: res.body,
                    subscrTotal: res.total,
                    totalPriceOrder: res.total
                })
            });
    };

    backMain = () => {
        this.setState({
            isSubscribe: false
        })
    };

    handleAuthBtnClick = () => {
        const login_btn = document.getElementsByClassName('user-btn')[0];
        login_btn ? login_btn.click() : this.setState({
            isNoBtn: true
        });
    };

    enterCreds = (event) => {
        if (event.id === 'unathPhone') {
            switch (event.id) {
                case 'unathPhone':
                    if (event.length > 13 || event.value.search(/_/) > -1) {
                        this.setState({
                            errorChangePhone: true,
                            changePhone: true
                        })
                    } else if (event.length < 13) {
                        this.setState({
                            errorChangePhone: true
                        })
                    } else if (event.length === 13) {
                        this.setState({
                            errorChangePhone: false,
                            btnOrder: true
                        })
                    } else {
                        this.setState({
                            changePhone: false,
                            errorChangePhone: false
                        })
                    }
                    break;
                default:
                    break;
            }
        }
        this.setState({
            [event.id]: event.value,
            changePhone: true
        })
    };

    reserve = () => {
        this.setState({
            reserve: true
        })
    };

    reserveCancel = () => {
        this.setState({
            reserve: false
        })
    };

    cancelBuySubscription = () => {
        this.setState({
            isSubscribe: false
        })
    };

    changeUserSubscription = () => {
        this.setState({
            changeSubscription: true,
        });
    };

    cancelChangeSubscription = () => {
        this.setState({
            changeSubscription: false
        })
    };

    // add subscription to options id:05
    addSubscriptionToOptions = (id, s) => {
        if (s.age === "adult") {
            this.setState({
                subscriptionId: id,
                changeSubscription: false,
                changeSubsc: true,
                subscriptionChange: true
            });
        } else if (s.age === "child") {
            this.setState({
                subscriptionId: id,
                usedChildSubscription: true,
                changeSubscription: false,
                subscriptionChange: true,
                changeSubsc: true,
            });
            setTimeout(() => this.setState({usedChildSubscription: false}), 4000)
        } else {
            this.setState({
                subscriptionChange: false
            })
        }
    };

    buyOrders = () => {
        this.setState({
            isAuth: true,
            //buyOrder: true,
            isEnterCred: true
        })
    };

    handleBuyBtnClick = () => {
        this.setState({
            buyOrder: true,
            //isEnterCred: false,
            isAuth: true
        });
        //this.getUserSubscriptions();
    };

    handleChangeCheckbox = () => {
        this.setState({
            checkboxRules: !this.state.checkboxRules,
            errorChangePhone: !this.state.errorChangePhone
        })
    };

    showRules = () => {
        this.setState({
            showRules: !this.state.showRules
        })
    };

    render() {
        if (this.state.isLoading) {
            return (
                <div className="order-modal App-loading">
                    <div className="loading"/>
                </div>
            )
        } else if (!this.state.isAuth || this.state.isAuthShown) {
            return (
                <React.Fragment>
                    <form onSubmit={this.handleBuyBtnClick} className="order-modal">
                        <div className="formTitle">Введите данные</div>
                        <div className="creds">
                            <FormControl
                                type="tel"
                                id="unathPhone"
                                name="unathPhone"
                                isValid={true}
                                required={true}
                                label="телефон"
                                value={this.state.unathPhone}
                                handleChange={this.enterCreds}/>
                            <FormControl
                                type="email"
                                id="unathEmail"
                                name="Email"
                                label="Email"
                                isValid={true}
                                value={this.state.unathEmail}
                                handleChange={this.enterCreds}/>
                            {/*<div className="rules-checkbox">*/}
                            {/*    <input style={{cursor: 'pointer'}} type="checkbox" id="rules"*/}
                            {/*           onChange={this.handleChangeCheckbox} checked={this.state.checkboxRules}/>*/}
                            {/*    <label style={{cursor: 'pointer'}} htmlFor="rules">Соглашаюсь с правилами СРК*/}
                            {/*        Янрарь</label>*/}
                            {/*</div>*/}
                            {/*{this.state.checkboxRules &&*/}
                            {/*<span style={{cursor: 'pointer'}} onClick={this.showRules}>Подробности</span>}*/}
                        </div>
                        {this.state.errorChangePhone === true //&& this.state.showRules === false
                            ?
                            <button className="sendOrderDisable" disabled><span>Выбрать заказ</span></button>
                            :
                            <button className="sendOrder" type="submit"><span>Выбрать заказ</span></button>
                        }
                        {this.state.errorChangePhone &&
                        <div className="error">Проверьте правильность ввода телефона</div>}
                        {/*{!this.state.checkboxRules && <div className="error">Подтвердите соглашение с правилами</div>}*/}
                    </form>
                    <React.Fragment>
                        <Modal className={this.props.className} size="lg" centered isOpen={this.state.showRules}
                               toggle={this.showRules}>
                            <ModalHeader toggle={this.showRules}>Расписка на посещение аквапарка!</ModalHeader>
                            <ModalBody>
                                Расписка на посещение аквапарка
                            </ModalBody>
                            <ModalFooter>
                                <Button>Соглашаюсь</Button>
                            </ModalFooter>
                        </Modal>
                    </React.Fragment>
                </React.Fragment>
            )
        }

        // DON`T REMOVE THIS !!!!!

        // else if (!this.state.isAuth || this.state.isAuthShown) {
        //     return (
        //         <div>
        //             {/*<div className="order-modal">*/}
        //             {/*    <div className="formTitle">Для совершения заказа Вам необходимо авторизоваться</div>*/}
        //             {/*    <div className="sendOrder" onClick={this.handleAuthBtnClick}><span>Войти</span></div>*/}
        //             {/*    {this.state.isNoBtn && <div className="error">Нет формы авторизации / регистрации!</div>}*/}
        //             {/*</div>*/}
        //             <div className="order-modal">
        //                 <div className="formTitle">Заказ без авторизации</div>
        //                 <div className="sendOrder" onClick={this.buyOrders}><span>Сделать заказ</span></div>
        //             </div>
        //         </div>
        //     )
        // }
        else if (this.state.isPayForm) {
            return (
                <div className="order-modal">
                    <div className="formTitle">Оплатить заказ</div>
                    <div className="formTotal">К оплате: {this.state.totalPriceOrder} грн.</div>
                    <div className="payButton" dangerouslySetInnerHTML={{__html: this.state.payForm}}/>
                </div>
            )
        } else if (this.state.changeSubscription === true) {
            return (
                <div className="order-modal">
                    <div className="formTitle">Использовать абонемент</div>
                    <UserSubscriptions
                        userSubscriptions={this.state.userSubscriptions}
                        addSubscriptionToOptions={this.addSubscriptionToOptions}
                        cancelChangeSubscription={this.cancelChangeSubscription}
                        userName={this.state.userName}
                    />
                </div>
            )
        } else if (window.location.href.search(/#subscription/) > -1 || this.state.isSubscribe) {
            if (!this.state.isAuth) {
                return (
                    <div className="order-modal">
                        <div className="formTitle">Для совершения заказа Вам необходимо авторизоваться</div>
                        <div className="sendOrder" onClick={this.handleAuthBtnClick}><span>Войти</span></div>
                        {this.state.isNoBtn && <div className="error">Нет формы авторизации / регистрации!</div>}
                    </div>
                )
            } else {
                return (
                    <div className="order-modal">
                        <div className="formTitle">Купить абонемент</div>
                        <Subscriptions backMain={this.backMain} array={this.state.subscriptions}
                                       handleBuy={this.doOrderSubscription}
                                       cancelBuy={this.cancelBuySubscription}
                        />
                    </div>
                )
            }
        } else {
            return (
                <div className="order-modal">
                    {this.state.reserve === true
                        ?
                        <div className="order-modal">
                            {this.state.visit === false
                                ?
                                <div className="formTitle">Сделать резерв</div>
                                :
                                <div className="formTitle">Посетить</div>
                            }
                            {this.state.orders.map(o => {

                                return (<OrderForm key={o.id.toString()}
                                                   tosubscribe={this.toSubscriptions}
                                                   user={this.state.user}
                                                   products={this.state.products}
                                                   order={o}
                                                   selectedStart={o.time.start}
                                                   selectedEnd={o.time.end}
                                                   changeActiveProduct={this.changeActiveProduct}
                                                   changeActiveOption={this.changeActiveOption}
                                                   changeDate={this.changeDate}
                                                   getAvailableDates={this.getAvailableDates}
                                                   addOption={this.addOption}
                                                   removeOption={this.removeOption}
                                                   changeOption={this.changeOption}
                                                   changeTime={this.changeTime}
                                                   plusPeople={this.peoplePlus}
                                                   childrenPlus={this.childrenPlus}
                                                   minusPeople={this.peopleMinus}
                                                   removeOrder={this.removeOrder}
                                                   reserve={this.state.reserve}
                                                   changeUserSubscription={this.changeUserSubscription}
                                                   failOrder={this.state.failOrder}
                                                   visit={this.state.visit}
                                                   subscriptionChange={this.state.subscriptionChange}
                                                   timeNow={this.state.timeNow}
                                                   buyOrder={this.state.buyOrder}
                                                   poolTime={this.state.poolTimeWork}
                                                   price={this.state.price}
                                                   poolPrice={this.state.poolPrice}
                                                   changeSecondOrder={this.state.changeSecondOrder}
                                >
                                </OrderForm>)
                            })}
                            <div className="addProduct" onClick={this.addOrderItem}>
                                <div className="cross"/>
                                Добавить еще одну услугу
                            </div>
                            <div className="ordersTotal"><span>Всего вместе</span>
                                <div>{(() => {
                                    let sum = 0;
                                    this.state.orders.map(o => sum += o.total);
                                    return sum;
                                })()} грн
                                </div>
                            </div>
                            {this.state.failOrder &&
                            <span className="error-order">Выберите корректную дату и время</span>}
                            {this.state.changeActiveDate &&
                            <span className="error-order">Сделать заказ можно не более, чем за 7 дней до события</span>}
                            {this.state.visit === true
                                ?
                                <div onClick={this.doOrderReserve} className="sendOrder"><span>Посетить</span></div>
                                :
                                <div onClick={this.doOrderReserve} className="sendOrder"><span>Сделать резерв</span>
                                </div>
                            }
                            <div onClick={this.reserveCancel} className="sendOrder"><span>Отменить</span></div>
                        </div>
                        :
                        <React.Fragment>
                            {this.state.visit === false
                                ?
                                <div className="formTitle">Сделать резерв</div>
                                :
                                <div className="formTitle">Заказать услугу</div>
                            }
                            {this.state.orders.map((o, index) => {
                                return (<OrderForm key={o.id.toString()}
                                                   index={o.product.main.id}
                                                   tosubscribe={this.toSubscriptions}
                                                   user={this.state.user}
                                                   products={this.state.products}
                                                   order={o}
                                                   selectedStart={o.time.start}
                                                   selectedEnd={o.time.end}
                                                   changeActiveProduct={this.changeActiveProduct}
                                                   changeActiveOption={this.changeActiveOption}
                                                   changeDate={this.changeDate}
                                                   getAvailableDates={this.getAvailableDates}
                                                   addOption={this.addOption}
                                                   removeOption={this.removeOption}
                                                   changeOption={this.changeOption}
                                                   changeTime={this.changeTime}
                                                   plusPeople={this.peoplePlus}
                                                   childrenPlus={this.childrenPlus}
                                                   minusPeople={this.peopleMinus}
                                                   childrenMinus={this.childrenMinus}
                                                   removeOrder={this.removeOrder}
                                                   reserve={this.state.reserve}
                                                   changeUserSubscription={this.changeUserSubscription}
                                                   visit={this.state.visit}
                                                   subscriptionChange={this.state.subscriptionChange}
                                                   timeNow={this.state.timeNow}
                                                   activeProductId={this.state.activeProductId}
                                                   failOrder={this.state.failOrder}
                                                   orderGood={this.state.orderGood}
                                                   usedChildSubscription={this.state.usedChildSubscription}
                                                   userSubscriptions={this.state.userSubscriptions}
                                                   dateForSubscription={this.state.dateForSubscription}
                                                   changePoolTime={this.changePoolTime}
                                                   activeId={this.state.activeProductId}
                                                   buyOrder={this.state.buyOrder}
                                                   poolTime={this.state.poolTimeWork}
                                                   price={this.state.price}
                                                   poolPrice={this.state.poolPrice}
                                                   orders={this.state.orders}
                                                   eveningPreferredProduct={this.state.eveningPreferredProduct}
                                                   userBday={this.state.userBday}
                                                   userDiscount={this.state.userDiscount}
                                                   changePoolDiscount={this.state.changePoolDiscount}
                                                   changeDiscount={this.state.changeDiscount}
                                                   changedPoolDiscount={this.changedPoolDiscount}
                                                   changedDiscount={this.changedDiscount}
                                                   loadingSubscription={this.state.loadingSubscription}
                                >
                                </OrderForm>)
                            })}
                            {this.state.subscriptionChange === false && this.state.visit === true
                                ?
                                <div className="addProduct" onClick={this.addOrderItem}>
                                    <div className="cross"/>
                                    Добавить еще одну услугу
                                </div>
                                : null
                            }
                            <div className="ordersTotal"><span>Всего вместе</span>
                                <div>{(() => {
                                    let sub = this.state.subscriptionChange;
                                    let trampoline = this.state.removeDiscountTrampoline;
                                    let sum = 0;
                                    this.state.orders.map(o =>
                                            sub === true
                                                ?
                                                sum
                                                :
                                                trampoline === true && o.product.main.id === '01'
                                                    ?
                                                    sum += o.total //* 2
                                                    :
                                                    sum += o.total
                                        //+ parseInt(o.options.map(opt => opt.price).join())
                                    );
                                    return sum;
                                })()} грн
                                </div>
                            </div>
                            {this.state.failOrder &&
                            <span className="error-order">Выберите корректную дату и время</span>}
                            {this.state.changeActiveDate &&
                            <span
                                className="error-order">Сделать заказ можно не более, чем за {this.state.preordered} дней до события</span>}

                            {this.state.visit === true
                                ?
                                <div>
                                    <div onClick={this.doOrder} className="sendOrder"><span>Заказать</span></div>
                                    {/*{this.state.isSubscribe === false*/}
                                    {/*    // && this.state.activeProductId !== '05'*/}
                                    {/*    ?*/}
                                    {/*    <div onClick={this.toSubscriptions} className="buySubscription"><span>Купить абонемент</span>*/}
                                    {/*    </div>*/}
                                    {/*    : null*/}
                                    {/*}*/}
                                </div>
                                :
                                <div>
                                    <div onClick={this.doOrderReserve} className="sendOrder"><span>Сделать резерв</span>
                                    </div>
                                    {/*{this.state.isSubscribe === false*/}
                                    {/*    // && this.state.activeProductId === '05'*/}
                                    {/*    ?*/}
                                    {/*    <div onClick={this.toSubscriptions} className="buySubscription"><span>Купить абонемент</span>*/}
                                    {/*    </div>*/}
                                    {/*    : null*/}
                                    {/*}*/}
                                </div>
                            }
                            {/*<div onClick={this.reserve} className="sendOrder"><span>Забронировать</span></div>*/}
                        </React.Fragment>
                    }
                </div>
            )
        }
    }
}

export default OrderWindow;