import React, { Component } from "react";
import SignIn from "./containers/SignIn";
import SignUp from "./containers/SignUp";
// import EnterCode from "./containers/EnterCode";
import EnterPhone from "./containers/EnterPhone";
import Cookies from 'js-cookie';
import "./SignInOutModal.scss";
import RestorePass from "./containers/RestorePass";
import EnterRestoreCode from "./containers/EnterRestoreCode";
export const BASE_URL = 'https://apip.skyantar.com/';

class SignInOutModal extends Component {
    state = {
        isLoading: true,
        authToken: Cookies.get('zplAntrATkn-99'),

        userLogged: false,

        restorePhone: '',
        restoreSign: '',
        restoreCode: '',

        isMain: true,
        isLogged: false,
        isCode: false,
        isRestoreCode: false,
        isThx: false,
        isPhone: false,
        isRestore: false,
        showModal: false,
        isRestoredPass: false,
    };
    onRestore = () => {
        this.setState({
            isMain: false,
            isLogged: false,
            isCode: false,
            isRestoreCode: false,
            isThx: false,
            isPhone: false,
            isRestore: true
        });
    };

    onRestoredPass = () => {
        this.setState({
            restorePhone: '',
            restoreSign: '',
            restoreCode: '',

            isMain: false,
            isLogged: false,
            isCode: false,
            isRestoreCode: false,
            isThx: false,
            isPhone: false,
            isRestore: false,
            isRestoredPass: true
        });
    };

    goHome = () => {
        this.setState({
            restorePhone: '',
            restoreSign: '',
            restoreCode: '',

            isMain: true,
            isLogged: false,
            isCode: false,
            isRestoreCode: false,
            isThx: false,
            isPhone: false,
            isRestore: false,
            isRestoredPass: false,
        });
    };

    becameLogged = (userToken) => {
        let date = new Date(new Date().getTime() + 86400000);
        document.cookie = `zplAntrATkn-99=${userToken}; expires=${date.toUTCString()}`;
        this.setState({
            isMain: false,
            isCode: false,
            isThx: false,
            isLogged: true,

            userLogged: true,
            authToken: userToken
        });
        // window.location.href.search(/#subscription/) > -1 &&
        setTimeout(function () { window.location.reload() }, 10);
    };

    goCode = (user, sign, code) => {
        this.setState({
            isMain: false,
            isCode: true,
            isThx: false,
            isLogged: false,
            newUser: { ...user },
            sign: sign,
            code: code
        })
    };

    thxRegister = () => {
        this.setState({
            isMain: false,
            isCode: false,
            isThx: true,
            isLogged: false,
        })
    };

    logOut = () => {
        fetch(`${BASE_URL}logout`, {
            headers: {
                'Authorization': `Bearer ${this.state.authToken}`
            },
            method: 'post',
        }).then(res => {
            switch (res.status) {
                case 200:
                    this.setState({ userLogged: false, isLoading: false });
                    Cookies.remove('zplAntrATkn-99');
                    localStorage.clear();
                    window.location.reload();
                    break;
                default:
                    console.log('error server');
                    this.setState({ userLogged: true, isLoading: false });
                    break;
            }
        }).catch(
            err => {
                console.log(err.message)
            }
        );
        this.setState({
            userLogged: false,
            isLogged: false,
            isMain: true
        })
    };

    goRestore = (phone, sign, code) => {
        this.setState({
            restorePhone: phone,
            restoreSign: sign,
            restoreCode: code,
            isMain: false,
            isCode: false,
            isCodeRestore: false,
            isRestore: true,
            isThx: false,
            isLogged: false,
        })
    };

    goRestoreCode = (sign, phone) => {
        this.setState({
            restorePhone: phone,
            restoreSign: sign,
            isMain: false,
            isCode: false,
            isCodeRestore: true,
            isRestore: false,
            isThx: false,
            isLogged: false,
            isPhone: false,
        })
    };

    goPhone = () => {
        this.setState({
            isMain: false,
            isCode: false,
            isCodeRestore: false,
            isRestore: false,
            isThx: false,
            isLogged: false,
            isPhone: true
        })
    };

    toggleModal = () => {
        if (!this.state.isMain) {
            this.setState({
                isMain: true,
                isCode: false,
                isCodeRestore: false,
                isRestore: false,
                isThx: false,
                isLogged: false,
                isPhone: false
            })
        }
        this.setState({
            showModal: !this.state.showModal,
        })
    };

    componentDidMount() {
        fetch(`${BASE_URL}user`, {
            headers: {
                'Authorization': `Bearer ${this.state.authToken}`
            },
            method: 'get',
        }).then(res => {
            switch (res.status) {
                case 200:
                    this.setState({ userLogged: true, isLoading: false });
                    document.getElementsByClassName('item-137')[0].style = "display:inline-block;";
                    return res.json();
                default:
                    this.setState({ userLogged: false, isLoading: false });
                    document.getElementsByClassName('item-137')[0].style = "display:none;";
                    break;
            }
        }).then(res => res).catch(
            err => {
                console.log(err.message)
            }
        )
    }

    render() {
        const modal = (
            <React.Fragment>
                <div className="modalWrap">
                    <div className="auth-modal">
                        <div className="close" onClick={this.toggleModal}>
                            <div />
                            <div />
                        </div>
                        {this.state.isMain && <div className="auth-main">
                            <SignIn onLogin={this.becameLogged} goRestore={this.goPhone} />
                            <SignUp onRegister={this.thxRegister} />
                        </div>}
                        {this.state.isPhone && <div className="auth-restore">
                            <EnterPhone onEnter={this.goRestoreCode} />
                        </div>}
                        {this.state.isCodeRestore &&
                            <div className="auth-code">
                                <EnterRestoreCode phone={this.state.restorePhone} onEnter={this.goRestore}
                                    sign={this.state.restoreSign} onRestore={this.onRestore} />
                            </div>}
                        {this.state.isRestore && <div className="auth-restore">
                            <RestorePass phone={this.state.restorePhone} sign={this.state.restoreSign} code={this.state.restoreCode} onRestore={this.onRestoredPass} />
                        </div>}
                        {this.state.isThx && <div className="auth-thanks">
                            <span>Спасибо за регистрацию!</span>
                            <button onClick={this.goHome} type="auth-submit" className="appSubmit">
                                <span>Войти</span>
                            </button>
                        </div>}
                        {this.state.isRestoredPass && <div className="auth-thanks">
                            <span>Ваш пароль изменен!</span>
                            <button onClick={this.goHome} type="auth-submit" className="appSubmit">
                                <span>Войти</span>
                            </button>
                        </div>}
                        {this.state.isLogged && <div className="auth-thanks">
                            <span>Вы авторизированны!</span>
                        </div>}
                    </div>
                </div>
            </React.Fragment>
        );

        const { isLoading, showModal, userLogged } = this.state;
        return (
            <React.Fragment>
                <button className="user-btn" onClick={userLogged ? this.logOut : this.toggleModal}>
                    {
                        isLoading ?
                            <div className="auth-loading" />
                            :
                            userLogged ? <span>Выйти</span> : <span>Авторизация</span>
                    }
                </button>
                {showModal && modal}
            </React.Fragment>
        );
    }
}

export default SignInOutModal;
